@import './_settings';

.smartphone--dark {
  background-color: $gray2;
  border-color: $gray2;
}

.smartphone--white {
  background-color: $gray7;
  border-color: $gray7;
}

.smartphone {
  position: relative;
  border-style: solid;
  border-width: 7px 6px 16px;
  border-radius: 12px;
  z-index: 1;
  width: 297px;

  box-shadow: 0 47px 33px 0 rgba($black, 0.08);

  img {
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.smartphone--inside {
  height: 397px;
  width: 221px;
  position: absolute;
}

.smartphone--outside {
  min-width: 297px;
}

.smartphone--scale {
  @media (max-width: $screen-tiny-max) {
    transform: scale(0.6);
  }

  @media (min-width: $screen-small) and (max-width: $screen-small-max) {
    transform: scale(0.9);
  }

  @media (min-width: $screen-medium) and (max-width: $screen-medium-max) {
    transform: scale(0.7);
  }
}

.font-weight-bold {
  font-weight: bold !important;
}

.screen--dark {
  background-color: $gray2;
  border-color: $gray2;

  &::before,
  &::after {
    background-color: $gray4;
  }
}

.screen--white {
  background-color: $gray7;
  border-color: $gray7;

  &::before,
  &::after {
    background-color: $gray5;
  }
}

.screenshot--one {
  .smartphone {
    bottom: -90px;
    right: -43px;
  }

  @media (min-width: $screen-small) {
    .smartphone {
      bottom: -30px;
      right: 0;
    }
  }
}

.screenshot--two {
  margin-top: 60px; //

  .smartphone {
    left: 4px;
    top: -110px;

    @media (min-width: $screen-small) and (max-width: $screen-small-max) {
      top: -60px; // offset negative value with margin-top
      left: 44px;
    }

    @media (min-width: $screen-medium) and (max-width: $screen-medium-max) {
      left: 14px;
      top: -100px;
    }

    @media (min-width: $screen-large) {
      left: 64px;
    }
  }
}

.screen {
  position: relative;
  border-width: 38px 8px 8px 8px;
  border-style: solid;
  border-radius: 10px;
  //width:100%;
  //height:403px;

  box-shadow: 0 20px 10px 0 rgba($black, 0.06);

  &::before {
    width: 12px;
    height: 12px;
    top: -25px;
    left: 20px;
    border-radius: 50%;
  }

  &::after {
    width: 60%;
    top: -28px;
    left: 55%;
    transform: translateX(-50%);
    height: 18px;
    border-radius: 9px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
  }

  img {
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.screen--compact {
  width: calc(100% - 45px);

  @media (min-width: $screen-small) {
    width: calc(100% - 75px);
  }
}
