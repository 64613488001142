.home-advantages {
  position: relative;
  width: 100%;
  color: var(--primaryColor);

  .content {
    gap: 10px;
    align-items: center;
    display: flex;

    .header--content {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .heading {
        font-family: var(--fontFancy);
        font-weight: 900;
        font-size: 2.8rem;
        line-height: 3.2rem;
        color: var(--primaryColor);
      }

      .description {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        font-size: 1.6rem;
        line-height: 2.2rem;
        word-wrap: break-word;
      }
    }

    .btn-container {
      display: block;
      width: 280px;
      display: flex;
      justify-content: flex-end;

      .btn {
        font-size: 16px;
        gap: 10px;
        width: 100%;

        align-items: center;
        box-shadow: 0 0 0 0 transparent;
        display: flex;
        flex-wrap: nowrap;
        font-weight: 600;
        line-height: 20px;
        transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out,
          color 0.2s ease-in-out, fill 0.2s ease-in-out;
        white-space: nowrap;
        background-color: var(--primaryColor);
        color: #fcfcfc;
        border-radius: 28px;
      }
    }
  }

  .services-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    width: 100%;

    .card-advantage {
      border-radius: 20px;
      position: relative;
      background-color: var(--secondaryColorLight);
      width: 100%;
      padding: 15px 10px;

      .icon-holder {
        height: 60px;
        width: 60px;
        justify-content: center;
        align-items: center;
        display: flex;
        fill: var(--primaryColor);
        background-color: var(--secondaryColor);
        border-radius: 50%;
        margin-bottom: 20px;

        .icon {
          height: 20px;
          min-width: 20px;
          width: 20px;
          transform: rotate(90deg);
        }

        .edit-icon {
          z-index: 1;
        }
      }

      .card-heading {
        font-family: var(--fontFancy);
        font-weight: 900;
        font-size: 2.8rem;
        line-height: 3.2rem;
        letter-spacing: -2px;
        margin-bottom: 15px;
        width: 100%;
        color: var(--primaryColor);
      }

      .tags {
        gap: 8px;
        align-items: flex-end;
        flex-wrap: wrap;
        display: flex;
        font-weight: 600;
        line-height: 3rem !important;

        .tag {
          border-radius: 4px;
          padding-left: 5px;
          padding-right: 5px;
          background-color: var(--primaryColorDark);
          color: var(--secondaryColorLighter);
        }
      }

      .description {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        font-size: 1.4rem;
        line-height: 1.8rem;
        word-wrap: break-word;
        color: var(--primaryColor);
      }

      .btn-container {
        display: block;
        margin-bottom: 20px;

        .btn {
          font-size: 16px;
          gap: 10px;

          align-items: center;
          box-shadow: 0 0 0 0 transparent;
          display: flex;
          flex-wrap: nowrap;
          font-weight: 600;
          line-height: 20px;
          transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out,
            color 0.2s ease-in-out, fill 0.2s ease-in-out;
          white-space: nowrap;
          background-color: var(--primaryColor);
          color: var(--secondaryColor);
          border-radius: 28px;
        }
      }

      .figure {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0;
        left: 0;
        top: 0;
        height: 400px;
      }
    }
  }

  .add-item {
    width: 100%;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    .add-item__inner {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: #e4e6ef;
      border-width: 2px !important;
      border-style: dashed !important;
      border-radius: 20px;
    }
  }
}
