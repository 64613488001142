.home-image-floating-left-description-icons {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-rows: auto;

  margin-bottom: 60px;

  .u-br-10 {
    border-radius: 10px;
  }

  .fill-secondary1 {
    fill: var(--secondaryColor);
  }

  .u-gap-25 {
    gap: 25px;
  }

  .bg-primary1 {
    background-color: var(--primaryColor);
  }

  .u-justify-center {
    justify-content: center;
  }

  .u-align-center {
    align-items: center;
  }

  .u\:width-48 {
    width: 48px;
    min-width: 48px;
    height: 48px;
  }

  .icon-24 {
    min-width: 24px;
    width: 24px;
    height: 24px;

    display: block;
  }

  .showcase {
    gap: 0;
    align-items: center;
    position: relative;

    width: 100%;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 15px;

    .showcase__content {
      grid-column: 1 / span 3;
      order: 2;
      grid-row: 1;

      .heading {
        font-family: var(--fontFancy);
        font-weight: 900;
        font-size: 2.8rem;
        line-height: 2.8rem;
        margin-bottom: 15px;
        width: 100%;
        color: $primary1;
      }

      .icon__outer {
        width: 48px;
        min-width: 48px;
        height: 48px;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 10px;
        fill: var(--secondaryColor);
        background-color: var(--primaryColorDark);

        .icon {
          min-width: 24px;
          width: 24px;
          height: 24px;
        }
      }

      .title {
        font-weight: 700;
        font-size: 1rem;
        line-height: 1rem;
        letter-spacing: 0.7px;
        color: $primary1;
        width: 100%;
      }

      .description_input {
        margin-bottom: 25px;
        width: 100%;
        color: $primary1;
        line-height: 2rem;
        font-family: $regular;
      }

      .icons {
        gap: 20px;
        flex-direction: column;
        display: flex;
      }
    }

    .showcase__figure {
      grid-column: 5 / span 7;
      order: 1;
      padding-bottom: 35px px;
      grid-row: 1;

      .content-image {
        overflow: hidden;
        border-radius: 1.5rem;

        img {
          object-fit: cover;
          display: inline-block;
          max-width: 100%;
          vertical-align: top;
        }
      }

      .showcase__cta {
        width: 100%;
        max-width: 280px;
        left: unset;
        margin: 0;

        right: 0;
        bottom: -50px;

        gap: 30px;
        z-index: 2;

        border-radius: 20px;
        position: absolute !important;

        padding: 35px;

        color: #fcfcfc;

        background-color: var(--primaryColor);

        .heading__cta {
          font-weight: 400;
          font-size: 1.5rem;
          line-height: 1.5rem;
          color: #fcfcfc;
          margin-bottom: 30px;
          font-family: $regular;
        }

        .content-button__inner {
          background-color: $secondary1;
          color: $primary2;
          font-family: $regular;
        }

        .text-primary-color {
          color: $primary2 !important;
          font-weight: 600;
        }
      }
    }
  }
}
