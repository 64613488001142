.form-package {
  width: 100%;

  display: grid;
	grid-template-columns: repeat(12, 1fr);

  .form-package__heading {
    width: 100%;

    grid-column: 2 / span 8;

    input {
      color: $primary1;
    }
  }

  .form-package__packages {
    width: 100%;

    grid-column: 2 / span 10;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2.5rem;

    .featured-item {
      height: 100%;

      color: $primary1;

      padding: 1.25rem;

      border-color: #e4e6ef;
      border-width: 2px !important;
      border-style: dashed !important;
      border-radius: 20px;

      .card__price {
        p::before {
            content: "€";
            font-size: 1.75rem;
        }

        span {
            font-size: 16px;
            line-height: 1;
            font-weight: 400;
            letter-spacing: 0;
            margin-left: 2px;
        }
      }
    }
    .add-item {
      width: 100%;
      min-height: 200px;
      height: 100%;
      display: flex;
      border-color: #e4e6ef;
      border-width: 2px !important;
      border-style: dashed !important;
      border-radius: 20px;
    }

  }

  .form-package__action {
    width: 100%;

    grid-column: 2 / span 8;

    a {
      border-radius: 28px;

      background-color: $primary1;

      padding: 1.25rem 2rem;
    }

    .action-text {
      text-align: center;
      color: #fcfcfc;
      font-size: 1rem;
      font-weight: 600;
    }
  }
}
