.home-three-icons-headings-and-descriptions {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-rows: auto;

  .usp {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px 10px;

    .usp__item {
      .icon {
        min-width: 38px;
        width: 24px;
        height: 24px;

        margin-bottom: 15px;

        fill: $primary1;
      }

      .heading {
        font-family: var(--fontFancy);
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 1.8rem;

        margin-bottom: 15px;
        color: $primary1;

        padding-bottom: 15px;
        border-bottom: 2px solid var(--secondaryColor) !important;

        width: 100%;
      }

      .description {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 15px;

        font-family: $regular;
        color: $primary1;

        word-wrap: break-word;
      }
    }
  }
}
