.info-block-blue {
  width: 100%;

  padding: 5.25rem 4.25rem;

  border-radius: 20px;
  background-color: $primary1;

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .title {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 15px;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1rem;
      word-wrap: break-word;
      color: var(--secondaryColor);
    }

    .heading {
      font-family: var(--fontFancy);
      font-weight: 900;
      font-size: 2.8rem;
      line-height: 2.8rem;
      margin-bottom: 25px;
      color: white;
    }

    .description {
      width: 100%;
      color: white;
    }

    .button {
      border-radius: 28px;
      font-size: 16px;
      gap: 20px;
      display: flex;
      font-weight: 600;
      flex-wrap: nowrap;
      align-items: center;
      line-height: 20px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      white-space: nowrap;
      box-shadow: 0 0 0 0 transparent;
      transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out,
        color 0.2s ease-in-out, fill 0.2s ease-in-out;
      color: #fcfcfc;
      background-color: var(--secondaryColor);

      font-family: $regular;

      .button__text {
        text-align: center;
        margin: 1rem 1rem;
        width: 100%;
        font-family: $fancy;
        color: $primary1;
        font-weight: 600;
      }
    }
  }
}
