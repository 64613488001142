.blog-featured {
	width: 100%;

	.blog-featured__heading {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1.25rem;

		.heading-title {
			width: 100%;
			color: $primary1;
			font-size: 2.75rem;
			font-weight: 900;
		}

		.heading-action {
			a {
				border-radius: 28px;

				background-color: $primary1;

				padding: 1.25rem 2rem;
			}

			.action-text {
				text-align: center;
				color: #fcfcfc;
				font-size: 1rem;
				font-weight: 600;
			}
		}
	}

	.blog-featured__list {
		width: 100%;

		margin-top: 3.25rem;

		display: grid;
		gap: 2rem;
		// grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
		grid-template-columns: 1fr 1fr 1fr;

        .add-item {
            width: 100%;
            min-height: 300px;

            .add-item__inner {
                width: 100%;
                height: 100%;
                display: flex;
                border-color: #e4e6ef;
                border-width: 2px !important;
                border-style: dashed !important;
                border-radius: 20px;
            }
        }

		.list-item {
			display: flex;
			flex-direction: column;
			gap: 0.75rem;

			.item-figure {
				overflow: hidden;
				border-radius: 0.75rem;

				img {
					width: 100%;
					max-height: 18rem;
					object-fit: cover;
				}
			}

			.item-post-type {
				position: relative;
				width: 100%;

				margin-top: 1rem;

				display: flex;
				flex-wrap: wrap;
				gap: 0.75rem;

				text-transform: uppercase;
				font-weight: 700;
				font-size: 0.875rem;
				line-height: 1.375rem;

				.post-type-category {
					border-radius: 4px;

					padding: 2px 8px;

					background-color: $primary3;

					color: #fcfcfc;
				}
			}

			.item-title {
				font-family: var(--fontRegular);
				color: var(--primaryColor);
				font-size: 1.25rem;
				font-weight: 700;
			}
		}
	}
}
