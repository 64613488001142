// - Utility classes
// ----------------------------------------------------------




// - Visibility
// -----------------------------

// visually hidden but readable to textreaders
.screen-reader-text,
.u-vh {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip-path: rect(0, 0, 0, 0);
	border: 0;
}


// hidden from everything
.u-hidden {display: none !important}

@media (max-width: $screen-tiny-max) {
	.sd\:u-hidden {display: none !important}
}

@media (max-width: $screen-mini-max) {
	.mi\:u-hidden {display: none !important}
}

@media (min-width: $screen-tiny) and (max-width: $screen-tiny-max) {
	.ti\:u-hidden {display: none !important}
}

@media (min-width: $screen-small) {
	.bd\:u-hidden {display: none !important}
}

@media (min-width: $screen-small) and (max-width: $screen-small-max) {
	.sm\:u-hidden {display: none !important}
}

@media (min-width: $screen-medium) and (max-width: $screen-medium-max) {
	.md\:u-hidden {display: none !important}
}

@media (min-width: $screen-large) {
	.lg\:u-hidden {display: none !important}
}



// - Positioning
// -----------------------------

.u-pin {position: absolute !important}

@media (max-width:$screen-tiny-max) {
	.sd\:u-pin {position: absolute}
}

@media (min-width:$screen-small) {
	.bd\:u-contain {position: relative}
}

@media (min-width:$screen-small) {
	.bd\:u-pin {position: absolute}
}

// contain absolute children
.u-contain {position: relative}

// contain fixed children
.u-trap {transform:translate(0)}

@media (min-width:$screen-medium) {
	.bd\:u-trap {transform:translate(0)}
}

// cover available space
.u-overlay {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0
}

// center element
.u-center {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%)
}


// - border-radius
// -----------------------------
.u-br-2 {border-radius: 2px}
.u-br-4 {border-radius: 4px}
.u-br-5 {border-radius: 5px}
.u-br-6 {border-radius: 6px}
.u-br-8 {border-radius: 8px}
.u-br-10 {border-radius: 10px}
.u-br-0-10 {border-radius: 0 10px}
.u-br-15 {border-radius: 15px}
.u-br-16 {border-radius: 16px}
.u-br-20 {border-radius: 20px}
.u-br-25 {border-radius: 25px}
.u-br-28 {border-radius: 28px}
.u-br-32 {border-radius: 32px}
.u-br-40 {border-radius: 40px}
.u-br-dot {border-radius:50%}

@media (min-width: $screen-small) {
	.bd\:u-br-20 {border-radius:20px}
}

// - Cursor
// -----------------------------

.u-cursor-pointer {cursor:pointer}
.u-cursor-not-allowed {cursor:not-allowed}

.u-quiet {opacity:0.4}

// - Grid
// ------------------------------------------------------------------------------------------------------------------------
.u-grid {
	display: grid;
}

@media (max-width: $screen-tiny-max) {
	.sd\:u-grid {
		display: grid;
	}
}

// @media (min-width: $screen-small) {
// 	.bd\:u-grid {
// 		display: grid;
// 	}
// }

@media (min-width:$screen-medium) and (max-width:$screen-medium-max) {
	.md\:u-grid {
		display: grid;
	}
}

@media (min-width:$screen-medium-large) {
	.ml\:u-grid {
		display: grid;
	}
}

@media (min-width:$screen-large) {
	.lg\:u-grid {
		display: grid;
	}
}

.u-grid-col-var {grid-template-columns: repeat(auto-fill, minmax(var(--width), 1fr))}

.u-grid-col-var-scale {
	grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));

	@media (min-width:$screen-small) {
		grid-template-columns: repeat(auto-fill, minmax(var(--width), 1fr));
	}
}


// - Flexing
// ------------------------------------------------------------------------------------------------------------------------
.u-flex {display: flex}

@media (max-width: $screen-tiny-max) {
	.sd\:u-flex {display: flex}
}

@media (min-width: $screen-small) {
	.bd\:u-flex {display: flex}
}

@media (min-width:$screen-small) and (max-width:$screen-small-max) {
	.sm\:u-flex {display:flex}
}

@media (min-width:$screen-medium) and (max-width:$screen-medium-max) {
	.md\:u-flex {display:flex}
}

@media (min-width:$screen-large) {
	.lg\:u-flex {display:flex}
}

.u-grow {flex-grow:1}
.u-wraps {flex-wrap: wrap}

.u-direction-column {flex-direction: column}
.u-direction-row {flex-direction: row}

@media (min-width:$screen-small) {
	.bd\:u-direction-row-reverse {flex-direction: row-reverse}
}

@media (max-width:$screen-tiny-max) {
	.sd\:u-wraps {flex-wrap:wrap}
	.sd\:u-direction-column {flex-direction: column}
}

@media (max-width:$screen-small-max) {
	.sm\:u-wraps-downwards {flex-wrap:wrap}
}

// - Align
// ------------------------------------------------------------------------------------------------------------------------

.u-align-center {align-items: center}
.u-align-start {align-items: start}
.u-align-self-start {align-self: flex-start}
.u-align-items-end {align-items:flex-end}
.u-align-items-right {align-items:right}

@media (max-width:$screen-tiny-max) {
	.sd\:u-align-center {align-items: center}
}

// @media (max-width:$screen-small-max) {
// 	.sd\:u-align-center {align-items: center}
// }



// - Gap
// ------------------------------------------------------------------------------------------------------------------------
.u-gap-5 {gap:5px}
.u-gap-8 {gap:8px}
.u-gap-10 {gap:10px}
.u-gap-14 {gap:14px}
.u-gap-15 {gap:15px}
.u-gap-18 {gap:18px}
.u-gap-20 {gap:20px}
.u-gap-25 {gap:25px}
.u-gap-30 {gap:30px}
.u-gap-40 {gap:40px}
.u-gap-45 {gap:45px}
.u-gap-50 {gap:50px}
.u-gap-60 {gap:60px}
.u-gap-80 {gap:80px}
.u-gap-var {gap:var(--gap)};

@media (max-width:$screen-tiny-max) {
	.sd\:u-gap-5 {gap:5px} 
	.sd\:u-gap-10 {gap:10px}
	.sd\:u-gap-15 {gap:15px}
	.sd\:u-gap-30 {gap:30px} 
	.sd\:u-gap-35 {gap:35px}
}

@media (min-width:$screen-small) {
	.bd\:u-gap-10 {gap:10px} 
	.bd\:u-gap-25 {gap:25px}
	.bd\:u-gap-30 {gap:30px}
	.bd\:u-gap-40 {gap:40px}
	.bd\:u-gap-45 {gap:45px}
	.bd\:u-gap-50 {gap:50px}
	.bd\:u-gap-60 {gap:60px}
}

@media (min-width:$screen-small) and (max-width:$screen-small-max) {
	.sm\:u-gap-20 {gap:20px}
	.sm\:u-gap-30 {gap:30px}
	.sm\:u-gap-35 {gap:35px}
}

@media (max-width:$screen-small-max) {
	.sm\:u-gap-20-downwards {gap:20px}
	.sm\:u-gap-25-downwards {gap:25px}
	.sm\:u-gap-30-downwards {gap:30px}
	.sm\:u-fullwidth-downwards {max-width:unset}
}

@media (min-width:$screen-medium) {
	.md\:u-gap-80-upwards {gap:80px}
	.md\:u-gap-30-upwards {gap:30px}
}

@media (max-width:$screen-mini-max) {
	.mi\:u-fullwidth {max-width:unset}
}

// - Justify
// ------------------------------------------------------------------------------------------------------------------------
.u-justify-center {justify-content: center}
.u-justify-between {justify-content: space-between}
.u-justify-evenly {justify-content: space-evenly}
.u-justify-around {justify-content: space-around}
.u-justify-end {justify-content: flex-end}
.u-justify-self-end {justify-self: end}

@media (max-width:$screen-tiny-max) {
	.sd\:u-justify-center {justify-content: center}
}

@media (max-width:$screen-small-max) {
	.sm\:u-justify-between-downwards {justify-content:space-between}
}

@media (min-width:$screen-small) {
	.bd\:u-justify-evenly {justify-content: space-evenly}
}


// - line
// ------------------------------------------------------------------------------------------------------------------------
.u-line4 {
	border-style:solid;
	border-width:4px
}

// stack order
//.u-z0 {z-index:0}
.u-z1 {z-index:1}
.u-z2 {z-index:2}
.u-z3 {z-index:3}
.u-z10 {z-index:10}
// overflow
.u-clip {overflow: hidden}

@media (min-width:$screen-small) {
	.bd\:u-clip {overflow: hidden}
}

// click through
.u-noevents {pointer-events: none}

.u\:width-35 {
	width:35px;
	min-width: 35px;
	height:35px
}

.u\:width-48 {
	width:48px;
	min-width: 48px;
	height:48px
}

@media (max-width:$screen-tiny-max) {
	.sd\:u-image-cover {
		object-fit: cover;
		object-position: center;
		width:100%;
		height:100%;
	}
}