.plugin-featured-plugins {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  .plugin-featured-plugins__content {
    display: flex;
    width: 100%;
    flex-direction: column;

    .heading {
      font-weight: 900;
      font-size: 2.8rem;
      line-height: 3.2rem;
      color: var(--primaryColor);
      font-family: var(--fontFancy);
    }

    .description {
      line-height: 2.2rem !important;
      letter-spacing: 0.7px;
      font-size: 1.4rem;
      word-wrap: break-word;
      color: var(--primaryColor);
    }
  }

  .plugin-featured-plugins__items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    column-gap: 8px;

    .add-item {
      width: 100%;
      min-height: 200px;

      .add-item__inner {
        width: 100%;
        height: 100%;
        display: flex;
        border-color: #e4e6ef;
        border-width: 2px !important;
        border-style: dashed !important;
        border-radius: 20px;
      }
    }

    .card {
      width: 100%;
      min-height: 200px;
      background-color: #d9e8ff;
      padding: 38px 20px 20px;
      gap: 10px;

      .heading {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: var(--primaryColor);
      }

      .description {
        line-height: 1.2rem !important;
        letter-spacing: 0.4px;
        font-size: 0.8rem;
        word-wrap: break-word;
        margin-bottom: 10px;
        color: var(--primaryColor);
      }

      .link {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 10px;

        .text {
          color: var(--primaryColor);
          font-weight: 400;
          font-family: var(--fontFancy);
        }

        .icon {
          transform: rotate(90deg);
          fill: var(--primaryColor);
        }
      }
    }
  }
}
