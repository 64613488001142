.media-video {
  position: relative;
  width: 100%;

  .content {
    margin-bottom: 10px;
    display: grid;
    grid-template-rows: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .inner {
      .heading {
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 2.4rem;
        letter-spacing: 1px;
        margin-bottom: 10px;
        width: 100%;
      }
      .description {
        word-wrap: break-word;
        width: 100%;
      }
    }
  }

  .figure {
    display: grid;
    grid-template-rows: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .inner {
      .link {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .border {
          border-style: solid;
          width: 75%;
          .item {
            width: 100%;
          }
        }
      }
      .caption {
        font-size: 1.2rem;
        line-height: 2.4rem;
        letter-spacing: 0.7px;
        word-wrap: break-word;
      }
    }
  }
}
