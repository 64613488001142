// - Heading sizes

// sizes: micro | mini | puny | tiny | small | regular | medium | large | huge | giant | colossal 
// alt: inscription | label | subtitle | title | headline | leader | screamer
// ----------------------------------------------------------

// Font family: Azeret Mono
// Font style: ExtraBold
// Font size: 40px
// Line height: 42px
// Line height: 90%
// Align: Left
// Vertical align: Top
// Letter spacing: -2px

.h- {

	&huuuuge {
		font-size: clamp(4.6rem, 3vw + 1rem, 6.4rem);
		line-height: clamp(4.8rem, 3vw + 1rem, 6.6rem);
		//letter-spacing: -2px;
	}
	&colossal {
		font-size: clamp(3.2rem, 3vw + 1rem, 4.8rem);
		line-height: clamp(3.6rem, 3vw + 1rem, 5.0rem);
		//letter-spacing: -2px;
	}
	&giant {
		font-size: clamp(3.2rem, 3vw + 1rem, 4rem);
		line-height: clamp(3.6rem, 3vw + 1rem, 4.2rem);
	}
	&huge {
		font-size: clamp(2.4rem, 3vw + 1rem, 3.2rem);
		line-height: clamp(3.2rem, 3vw + 1rem, 3.4rem);
		//letter-spacing: -1px;
	}
	&large {
		font-size: clamp(2.2rem, 2vw + 1rem, 2.8rem);
		line-height: clamp(4rem, 3vw + 1rem, 4.2rem);
	}
	&medium {
		font-size: clamp(2rem, 2vw + 1rem, 2.4rem);
		line-height: clamp(2.2rem, 3vw + 1rem, 3.2rem);
		//letter-spacing: -2px;
	}
	&small {
		font-size: clamp(2rem, 2vw + 1rem, 2.2rem);
		line-height: clamp(2.2rem, 3vw + 1rem, 2.4rem);
		//letter-spacing: 1px;
	}
	&tiny {
		font-size: clamp(1.8rem, 3vw + 1rem, 2.0rem);
		line-height: clamp(2.8rem, 3vw + 1rem, 3.2rem);
	}
	&puny {
		font-size: clamp(1.6rem, 3vw + 1rem, 1.8rem);
		line-height: clamp(2rem, 3vw + 1rem, 2.4rem);
		letter-spacing:0.7px;
	}
	&mini {
		font-size: 1.6rem;
		line-height: 2.4rem;
		letter-spacing:0.7px;
	}
	&micro {
		font-size: 1.5rem;
		line-height: 1.9rem;
		letter-spacing:0.5px;
	}
}

.sd\:h- {
	&.colossal {
		font-size: 4.8rem;
		line-height: 5.0rem;
	}
}

.t-break-word {
	word-break: break-word;
	hyphens: auto;
}

// - Text sizes
// sizes: micro | mini | puny | tiny | small | regular | medium | large | huge | giant | colossal 
// ----------------------------------------------------------
.prose {
	word-wrap: break-word;
}

.t- {
	&giant {
		font-size: 2.4rem;
		line-height: clamp(3.2rem, 0.6rem + 2vw, 3.8rem);
		letter-spacing: 0.25px;
	}

	&huge {
		font-size: clamp(1.6rem, 0.6rem + 2vw, 2.4rem);
		line-height: clamp(3.2rem, 0.6rem + 2vw, 3.8rem);
		letter-spacing: 0.25px;
	}

	&large {
		font-size: clamp(1.6rem, 0.6rem + 2vw, 2rem);
		line-height: clamp(2.8rem, 0.6rem + 2vw, 3.2rem);
		letter-spacing: 0.25px;
	}
	
	&medium {
		font-size: clamp(1.6rem, 0.6rem + 2vw, 1.8rem);
		line-height: clamp(2.8rem, 0.6rem + 2vw, 3.4rem);
		//letter-spacing: 0.25px;
	}
	
	&small {
		font-size: clamp(1.4rem, 0.6rem + 2vw, 1.6rem);
		line-height: clamp(2.6rem, 0.6rem + 2vw, 2.8rem);
		letter-spacing: 0.25px;
	}
	
	&tiny {
		font-size: clamp(1.3rem, 0.6rem + 2vw, 1.4rem);
		line-height: clamp(2.2rem, 0.6rem + 2vw, 2.2rem);
		letter-spacing: 0.25px;
	}

	&puny {
		font-size: 1.3rem;
		line-height: 2.2rem;
		letter-spacing: 0.7px;
	}

	&mini {
		font-size: 1.2rem;
		line-height: 2.4rem;
		letter-spacing: 0.7px;
	}

	&micro {
		font-size: 10px;
		line-height: 1;
		letter-spacing: 0.7px;
	}
}


// - line-height
// - add important to overwrite existing values without specifying more selectors
// ----------------------------------------------------------
.t-lh1 {line-height:1 !important}
.t-lh22 {line-height:2.2rem !important}
.t-lh24 {line-height:2.4rem !important}
.t-lh26 {line-height:2.6rem !important}
.t-lh28 {line-height:2.8rem !important}
.t-lh30 {line-height:3rem !important}
.t-lh32 {line-height: clamp(2.8rem, 3vw + 1rem, 3.2rem) !important}
.t-lh34 {line-height: clamp(2.8rem, 3vw + 1rem, 3.4rem) !important}

// - Letter-spacing
// ----------------------------------------------------------
.t-ls1- {letter-spacing:-1px; word-spacing: -10px;}
.t-ls2- {letter-spacing:-2px; word-spacing: -10px;}
//.ls4 {letter-spacing:0.4px;}
//.ls5 {letter-spacing:0.5px;}
//.ls10 {letter-spacing:1px}
//.ls10- {letter-spacing:-1px}
.t-ls075 {
	letter-spacing: 0.75px;
}
.t-ls3 {
	letter-spacing: 3px;
}

// - font-weight
// sizes: thin (100) | slim (200) | lighter (300) | regular (400) | median (500) | semi (600) | bold (700) | heavy (800) | black (900)
// ----------------------------------------------------------

.s- {
	&regular {
		font-weight: 400;
	}

	&medium {
		font-weight: 500;
	}

	&semi {
		font-weight: 600;
	}

	&bold {
		font-weight: 700;
	}

	&extrabold {
		font-weight: 900;
	}
}

.s-first-word span {
	display: block;
}

.s-regular-child span {
	font-weight:400;
	display: block;
}

.s-semi-child span {
	font-weight:600;
	display: block;
}

// - text classes
// ----------------------------------------------------------
// .t-truncate {
// 	text-overflow: ellipsis;
// 	white-space: nowrap;
// 	overflow: hidden;
// 	display: block;
// }
// .t-capitalize {
// 	text-transform: capitalize;
// }
.t-upper {
	text-transform: uppercase;
}
.t-italic {
	font-style: italic;
}	
.t-underline {
	text-decoration: underline;
}
.t-fancy {
	font-family:$fancy;
}
// .t-caption {
// 	display:table-caption;
// }
.t-captioned * {
	display:table-caption;
}


@media (max-width:$screen-tiny-max) {
	.sd\:t-caption {
		display: table-caption;
	}
}

// .t-false {
// 	text-decoration: line-through;
// }
.t-lede > p:first-child {
	font-weight: 600;
}
.t-quiet {
	opacity: 0.4;
}
.t-quiet- > * {
	opacity: 0.4;
}

@media (min-width:$screen-small) {
	.column-count-2 {
		column-count:2;

		> *:not(:last-child) {
			margin-bottom:30px;
		}
	}
}

.t-break-avoid {
	> p {
		break-inside: avoid;
	}
}

.t-no-wrap {
	white-space:nowrap;
}

// target specific child via <span>
// .uppered > span {text-transform: uppercase}
.s-bolded > * {font-weight:700}
// .italicized > span {@xtend .italic}
// .truncated > span {@extend .truncate;}

// - text alignment
// ----------------------------------------------------------
.t-center {
	text-align: center;
}
//.t-justify {text-align:justify}
// .t-alignleft {text-align:left}

@media (max-width: $screen-tiny-max) {
	.t-sd-center {
		text-align: center;
	}
	// .t-sd-justify {text-align:justify}
	// .t-sd-alignleft {text-align:left}
	// .t-sd-alignright {text-align:right}
}

@media (max-width:$screen-small-max) {
	.t-sm-center-down {
		text-align:center
	}
}

@media (min-width: $screen-small) {
	.t-bd-center {
		text-align: center;
	}
	// .t-bd-justify {text-align:justify}
	// .t-bd-alignleft {text-align:left}
	// .t-bd-alignright {text-align:right}
}

.t-alignright {
	text-align: right;
}

// - default text styling
// ----------------------------------------------------------

.prose {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap:30px;
}

.t-compact {
	gap:0;
}

// - wysiwyg styling
// use for WYSIWYG elements
// ----------------------------------------------------------

.wysiwyg {
	h1,
	h2 {
		font-size: clamp(2.7rem, 3vw + 1rem, 3.2rem);
		line-height: clamp(2.9rem, 3vw + 1rem, 3.4rem);
	}

	h3 {
		font-size: clamp(2.2rem, 3vw + 1rem, 3.2rem);
		line-height: clamp(2.8rem, 3vw + 1rem, 3.6rem);
	}

	h4 {
		font-size: clamp(2rem, 3vw + 1rem, 2.5rem);
		line-height: clamp(2.6rem, 3vw + 1rem, 3rem);
	}

	h5 {
		font-size: clamp(2rem, 3vw + 1rem, 2.4rem);
		line-height: clamp(2.6rem, 3vw + 1rem, 3.2rem);
	}

	h6 {
		font-size: clamp(2rem, 3vw + 1rem, 2rem);
		line-height: clamp(2.6rem, 3vw + 1rem, 2.4rem);
	}

	h1,
	h2 {
		margin-bottom: 3rem;
	}

	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 1.5rem;
	}

	h1,
	h2,
	h3 {
		font-weight: 700;
	}

	h4,
	h5,
	h6 {
		font-weight: 700;
	}

	ul:not([class]) {
		list-style-type: disc;
		list-style-position: outside;
		padding-left:14px;

		li {
			&::marker {
				color:inherit;
			}
		}
	}

	ol:not([class]) {
		list-style-type: numeric;
		list-style-position: inside;
		
		li {
			&::marker {
				font-weight: 700;
				color: inherit;
			}
		}
	}

	example {
		background-color:$primary5;
		border:1px solid $primary4;
		display: block;
		border-radius:20px;
		padding:30px 40px;
	}

	blockquote {
		font-size:32px;
		line-height:40px;
		// padding-top:30px;
		// padding-bottom:30px;
		letter-spacing: 1px;
		font-family:$fancy;
		font-weight:800;

		&::before {
			content:'“';
		}

		&::after {
			content:'”';
		}
	}

	a:not([class]) {
		text-decoration: underline;
	}
}

// - Default tags
// ----------------------------------------------------------
b {
	font-weight: 900;
}
strong {
	font-weight: 700;
}

i {
	font-style: italic;
}


// - definition styling
// ----------------------------------------------------------

// .t-definition {
// 	// dl {
// 	// }
// 	dt {
// 		float: left;
// 		width: 150px;
// 		font-weight: 700;
// 		@media (min-width: $screen-small) and (max-width: $screen-large) {
// 			width: 25%;
// 		}

// 		@media (min-width: $screen-large) {
// 			width: 50%;
// 		}
// 	}
// 	// dd {
// 	// }
// }

// - unordered list (ul)
// ----------------------------------------------------------

.t-unordered-list {
	li {
		position: relative;
		padding-left: 50px;
	}
}

// - link styling
// ----------------------------------------------------------

// set text decoration on child
.t-underlined {
	button[class=""],
	button:not([class]),
	a[class=""],
	a:not([class]) {
		text-decoration: underline;
	}
}

// show text-decoration on focus/hover child
// t-underlining moved to accessbility