.title-three-column-content {
  position: relative;
  display: grid;
  gap: 20px;
  width: 100%;

  .title {
    font-family: var(--fontFancy);
    font-weight: 900;
    font-size: 2.2rem;
    line-height: 2.8rem;
    margin-bottom: 10px;
    color: var(--primaryColor);
  }

  .grid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 20px;
    width: 100%;

    .add-item {
      width: 100%;
      min-height: 365px;
      display: flex;
      border-color: #e4e6ef;
      border-width: 2px !important;
      border-style: dashed !important;
      border-radius: 20px;
    }

    .card {
      position: relative;
      padding: 10px;

      .heading {
        font-family: var(--fontFancy);
        font-weight: 900;
        line-height: 3rem !important;
        font-size: 1.8rem;
        margin-bottom: 25px;
        width: 100%;
        color: var(--primaryColor);
      }

      .description {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
        letter-spacing: 0.25px;
        font-size: 1.2rem;
        line-height: 2.4rem;
        word-wrap: break-word;
        margin-bottom: 25px;
        width: 100%;
        color: var(--primaryColor);
      }

      .btn {
        flex-wrap: wrap;
        display: flex;

        .btn-text {
          background-color: transparent;
          border-radius: 0;
          border-style: solid;
          border-width: 0 0 2px;
          gap: 15px;
          justify-content: flex-start;
          padding: 0;
          font-size: 12px;
          color: var(--primaryColor);
          width: 100%;
        }
      }
    }
  }
}
