.home-packages {
  position: relative;
  width: 100%;

  .grid {
    max-width: 1160px;

    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-rows: auto;

    .grid__inner {
      width: 100%;
      .element__header {
        justify-content: space-between;
        gap: 20px;
        align-items: center;
        display: flex;
        margin-bottom: 40px;
        width: 100%;

        .heading {
          font-family: var(--fontFancy);
          font-weight: 900;
          font-size: 2.2rem;
          line-height: 3rem;
          color: $primary1;
        }

        .button {
          border-radius: 28px;
          font-size: 16px;
          gap: 20px;
          display: flex;
          font-weight: 600;
          flex-wrap: nowrap;
          align-items: center;
          cursor: pointer;
          line-height: 20px;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          white-space: nowrap;
          box-shadow: 0 0 0 0 transparent;
          transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out,
            color 0.2s ease-in-out, fill 0.2s ease-in-out;
          color: #fcfcfc;
          background-color: var(--primaryColor);

          font-family: $regular;

          .button__text {
            padding-right: 25px;
            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 25px;
            text-align: center;
            width: 100%;
            font-family: $fancy;
            $color: white;
          }
        }
      }

      .packages__cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        margin: 0;
        width: 100%;

        .loading {
          font-family: var(--fontFancy);
          font-weight: 900;
          font-size: 2.2rem;
          line-height: 3rem;
          color: $primary1;
        }

        .card {
          border-radius: 20px;
          position: relative;
          padding: 16px 10px 26px 16px;
          background-color: var(--primaryColorLighter);
          width: 100%;

          width: 100%;
          display: flex;
          justify-content: center;

          .card__header {
            justify-content: space-between;
            align-items: center;
            display: flex;
            margin-bottom: 10px;
            width: 100%;

            .card__package-name {
              margin-right: 10px;

              width: 100%;
              .card__heading {
                gap: 10px;
                white-space: pre-wrap;
                font-weight: 900;
                font-size: 1.3rem;
                line-height: 1.3rem;
                font-family: $regular;
                color: $primary1;
                margin-bottom: 10px;
                word-wrap: break-word;
              }

              .card__subtitle {
                white-space: pre-wrap;

                gap: 30px;

                font-size: 0.8rem;
                line-height: 0.8rem;

                word-wrap: break-word;
                font-family: $regular;
                color: $primary1;
              }
            }

            .card__holder {
              justify-content: center;
              align-items: center;
              display: flex;
              border-radius: 15px;
              fill: #ffb800;
              background-color: var(--primaryColorDark);
              padding: 10px;

              .icon {
                min-width: 25px;
                width: 25px !important;
                height: 25px !important;
              }
            }
          }

          .card__price {
            font-weight: 700;

            align-items: center;
            display: flex;
            column-gap: 10px;
            font-family: $regular;
            color: $primary1;

            margin-bottom: 15px;
            span {
              font-size: 1.2rem;
              line-height: 2.2rem;
            }
            small {
              font-size: 2.8rem;
              line-height: 2.8rem;
            }
          }

          .card__button {
            border-radius: 28px;
            font-size: 12px;
            gap: 10px;
            display: flex;
            font-weight: 600;
            flex-wrap: nowrap;
            align-items: center;
            cursor: pointer;
            line-height: 20px;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            white-space: nowrap;
            box-shadow: 0 0 0 0 transparent;
            transition: background-color 0.2s ease-in-out,
              border 0.2s ease-in-out, color 0.2s ease-in-out,
              fill 0.2s ease-in-out;

            color: var(--primaryColorDarker);
            background-color: var(--secondaryColor);

            .button__text {
              padding-right: 25px;
              padding-top: 8px;
              padding-bottom: 8px;
              padding-left: 25px;
              text-align: center;
              width: 100%;
            }
          }

          .card_pros-cons {
            gap: 15px;
            flex-direction: column;
            display: flex;

            .pro {
              gap: 10px;
              align-items: center;
              display: flex;
              fill: #fcfcfc;
              position: relative;

              .icon {
                min-width: 20px;
                width: 20px !important;
                height: 20px !important;
              }

              .heading {
                font-size: 0.8rem;
                line-height: 0.8rem;
                width: 100%;
                letter-spacing: 0.3px;
                font-family: $regular;
              }
            }

            .con {
              gap: 15px;
              align-items: center;
              display: flex;
              opacity: 0.4;
              position: relative;
              fill: var(--primaryColor);

              .icon {
                min-width: 10px;
                width: 10px !important;
                height: 10px !important;
              }

              .heading {
                font-size: 0.8rem;
                line-height: 0.8rem;
                width: 100%;
                letter-spacing: 0.3px;
                font-family: $regular;
              }
            }
          }
        }
      }
    }
  }
}
