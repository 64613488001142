// enable/disable compiling of card scss
$cards: true;

@if $cards == true {

	// - Related
	// -----------------------------------------------------------------------------
	.cards--related {
		// uses utility classes
		// display: grid;
		// grid-gap:35px;
		//grid-template-columns: repeat(3, minmax(0, 1fr));

		&.flickity-enabled {
			.card {
				width:300px;
				margin-right:25px;
			}
		}

		// passed on to a better place
		// @media (max-width:1059px) {
		// 	&::after {
		// 		content:'flickity';
		// 		display: none;
		// 	}
		// }
	}

	// - Addons
	// -----------------------------------------------------------------------------
	.cards--relavant-modules {
		&:not(.flickity-enabled) {
			// this is only for when the slider would be activated
			// also, a shit solution
			@media (max-width:$screen-medium-max) {
				min-height:292px;

				.card {
					display: none;
				}
			}
		}
	}

	.cards--cart-addons {
		&:not(.flickity-enabled) {
			// this is only for when the slider would be activated
			// also, a shit solution
			min-height:292px;

			.card {
				display: none;
			}
		}
	}


	// - Blogs
	// -----------------------------------------------------------------------------
	.cards--blogs {
		display: grid;
		grid-gap:50px 30px;

		@media (min-width:$screen-small) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		.card {
			&:first-of-type {

				@media (min-width:$screen-small) {
					grid-column:span 2;
				}

				.card__inner {
					padding:25px;
					background-color:$secondary4;
					border-radius:20px;
				}
			}
		}
	}

	.cards--related-blogs{
		&:not(.flickity-enabled) {
			// this is only for when the slider would be activated
			// also, a shit solution
			// screen-medium-large - 1px
			@media (max-width:1059px) {
				min-height:409px;

				.card {
					display: none;
				}
			}
		}
	}

	// - Portfolio
	// -----------------------------------------------------------------------------
	.cards--portfolio {
		display: grid;
		grid-gap:50px;
		grid-template-columns: repeat(6, minmax(0, 1fr));

		@media (min-width:$screen-medium) {
			grid-gap:80px 50px;
		}
	}

	// - Cases
	// -----------------------------------------------------------------------------
	.cards--related-cases {
		&:not(.flickity-enabled) {
			// this is only for when the slider would be activated
			// also, a shit solution
			@media (min-width:$screen-small) and (max-width:1059px) {
				min-height:395px;

				.card {
					display: none;
				}
			}
		}
	}

	.cards--cases {
		&:not(.flickity-enabled) {
			// this is only for when the slider would be activated
			// also, a shit solution
			@media (min-width:$screen-small) and (max-width:$screen-medium-max) {
				min-height:395px;

				.card {
					display: none;
				}
			}
		}
	}
	// - Highlights
	// -----------------------------------------------------------------------------
	.cards--process {
		grid-gap:35px 25px;

		@media (min-width:$screen-medium) and (max-width:$screen-medium-large-max) {
			&.toggler--open {
				.card--process {	
					&:nth-child(3) {
						grid-column:unset;
					}

					&:last-child:nth-child(odd) {
						grid-column:span 2;
					}
				}
			}
		}
	}


	// - Highlights
	// -----------------------------------------------------------------------------
	.cards--highlights {
		grid-gap:30px 20px;
	}


	// - Highlights
	// -----------------------------------------------------------------------------

	.cards--projects {

		@media (min-width:$screen-medium) {
			grid-gap:32px;
		}

		
		&:not(.flickity-enabled) {
			// this is only for when the slider would be activated
			// also, a shit solution
			@media (max-width:400px) {
				min-height:571px;
			}

			@media (min-width:401px) and (max-width:$screen-medium-max) {
				min-height:599px;
			}

			// screen-medium-large - 1px
			@media (max-width:1059px) {
				.card {
					display: none;
				}
			}
		}

		&.flickity-enabled {
			.card {
				width:100%;
				max-width:300px;
				margin-right:25px;
			}

			// .card--project-has-media {}

			.card__media {
				right:-16px;
			}
		}
	}

	// - Highlights
	// -----------------------------------------------------------------------------
	.cards--modules {
		&::after {
			display: none;
		}
	}

	// - Highlights
	// -----------------------------------------------------------------------------
	.cards--support {
		width:100%;
	}

	.cards--featured-support {	
		&:not(.flickity-enabled) {
			// this is only for when the slider would be activated
			// also, a shit solution
			min-height:393px;
		}

		.flickity-button {
			&.previous {
				left:40px;
			}
			&.next {
				right:40px;
			}
		}
	}

	// - Highlights
	// -----------------------------------------------------------------------------
	.cards--usp {
		grid-gap:50px;



		@media (min-width:$screen-large) {
			grid-gap:80px 30px;
		}
	}

	// - Highlights
	// -----------------------------------------------------------------------------
	.cards--reviews {
		padding-bottom:70px;
		column-gap: 30px;

		@media (min-width:$screen-medium) {
			column-count:2;
		}

		@media (min-width:$screen-large) {
			column-count:3;
		}
	}

	// - Highlights
	// -----------------------------------------------------------------------------
	.cards--packages {
		grid-gap:20px;
		// margin-left:-10px;
		// margin-right:-10px;
		
		&.flickity-enabled {
			.card {
				width:100%;
				max-width:290px;
				margin-right:10px;
				padding-top:42px;
			}

			.flickity-button {
				display: none;
			}
		}

		@media (min-width:$screen-small) {
			grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
			grid-gap:40px;
			margin:0;
		}

	}

	// - Highlights
	// -----------------------------------------------------------------------------
	.brands {

		@media (max-width:349px) {
			--width: calc(50% - 5px) !important;
		}

		@media (min-width:$screen-small) {
			&:not(.flickity-enabled) {
				// this is only for when the slider would be activated
				// also, a shit solution
				min-height:125px;
				
				.brand {
					display: none;
				}
			}
			
			&::after {
				content:'flickity';
				display: none;
			}
			
			flex-grow:1;
		}

		.flickity-button.next {
			transform:translateY(-50%);
			right:15px;
		}
	}

	// - Highlights
	// -----------------------------------------------------------------------------
	.cards--employees {
		@media (max-width:$screen-tiny-max) {
			--width:calc(50% - 10px) !important;
		}
		&.flickity-enabled {
			.card {
				padding-left:15px;
				width:305px;
				margin-right:3px;

				@media (min-width:$screen-small) {
					margin-right:10px;
				}
			}
		}
	}

	.cards--featured-employees {
		&:not(.flickity-enabled) {
			// this is only for when the slider would be activated
			// also, a shit solution
			min-height:462px;
			.card {
				display: none;
			}
		}

		&.flickity-enabled {
			.card {
				padding-left:15px;
				width:305px;
				margin-right:3px;

				@media (min-width:$screen-small) {
					margin-right:10px;
				}
			}
		}

		.flickity-button.next {
			right:70px !important;
		}
	}

	// - Highlights
	// -----------------------------------------------------------------------------
	.cards--plugins {
		@media (max-width:$screen-tiny-max) {
			&.flickity-enabled {
				&.u-flick-x-sd {
					.flickity-viewport {
						left:unset !important;
					}
					.flickity-slider {
						left:unset !important;
					}
				}
			}
		}
	}

	// .cards--used-plugins {}
	.cards--our-plugins,
	.cards--used-plugins {
		@media (max-width:$screen-medium-max) {
			&:not(.flickity-enabled) {
				position: relative;

				// this is only for when the slider would be activated
				// also, a shit solution
				@media (max-width:559px) {
					min-height:500px;
				}

				@media (min-width:560px) {
					min-height:516px;
				}
				
				// we dont want to see cards when we switch
				// from grid to flickity before its loaded
				.card {
					display: none;
				}
			}
		}
	}

	.cards--results {
		gap:40px;

		@media (min-width:$screen-medium) {
			gap:75px 40px;
		}
	}
}
