.specializations-faq {
  margin-bottom: 0;
  padding-bottom: 60px;
  margin-top: 40px;
  position: relative;
  width: 100%;

  .button-prime {
    background-color: var(--primaryColor) !important;
  }

  .specializations-grid {
    max-width: 860px;
    display: grid;
    grid-template-rows: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    grid-template-columns: repeat(12, 1fr);

    .grid__inner {
      grid-column: 3 / span 8;
    }

    .specializations-faq__content {
      .content-heading {
        font-weight: 900;
        letter-spacing: 1px;
        font-size: 2.4rem;
        line-height: 2.4rem;
        margin-bottom: 35px;
        padding-top: 10px;
        width: 100% !important;
        height: 100% !important;
        word-wrap: break-word;

        color: $primary1;
      }

      .content-description {
        color: $primary1;
        width: 100%;
        gap: 15px;
        font-size: 1.5rem;
        line-height: 1.5rem;
        z-index: 2;
        margin-bottom: 15px;
      }
    }

    .specializations-faq__list {
      gap: 5px;
      flex-direction: column;
      display: flex;
      position: relative;
      margin-bottom: 25px;
      width: 100%;

      .d_none_hover {
        :hover {
          display: none !important;
        }
      }

      .accordion__item {
        position: relative;

        :target {
          .accordion__toggle {
            fill: #fff;
            background-color: #2c80ff;
            color: #fff;
          }

          .accordion__icon {
            transform: rotate(0);
          }
        }

        a {
          text-decoration: none;
        }

        .accordion__text {
          font-weight: 600;
          font-size: 1.3rem;
          letter-spacing: 0.7px;
          line-height: 1rem;

          min-width: 300px;

          color: var(--secondaryColorLighter) !important;
        }

        .accordion__active {
          fill: $primary1 !important;
          background-color: var(--secondaryColor) !important;
          color: $primary1 !important;

          .accordion__text {
            color: $primary1 !important;
          }
          .accordion__icon {
            transform: rotate(0) !important;
          }
        }

        .accordion__toggle {
          // fill: #fff;
          // background-color: #2c80ff;
          // color: #fff;

          transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
            fill 0.3s ease-in-out;

          border-radius: 4px;

          fill: var(--secondaryColorLighter);
          background-color: var(--primaryColor);
          color: var(--secondaryColorLighter) !important;
          display: block;
          line-height: 20px;
          padding: 20px 75px 20px 17px;
          position: relative;
          text-align: left;
          width: 100%;
          z-index: 1;
        }

        .accordion__holder {
          justify-content: center;
          align-items: center;
          display: flex;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          width: 70px;

          .accordion__icon {
            height: 14px;
            min-width: 14px;
            width: 14px;

            transform: rotate(180deg);
          }
        }

        .accordion__inner {
          border-radius: 4px;
          padding-top: 15px;
          position: relative;
          z-index: 1;
          padding-left: 10px;
          padding-right: 10px;

          .accordion__heading {
            font-weight: 600;
            font-size: 1.2rem;
            line-height: 1rem;
            letter-spacing: 0.7px;
            margin-bottom: 7px;
            width: 100%;

            color: var(--primaryColor) !important;
          }

          .accordion__description {
            letter-spacing: 0.7px;
            word-wrap: break-word;
            width: 100%;
            margin-bottom: 7px;

            color: $primary1 !important;
            font-family: $regular;
          }

          .content-action {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
