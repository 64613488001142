.media-gallery {
  position: relative;
  width: 100%;

  .content {
    margin-bottom: 10px;
    display: grid;
    grid-template-rows: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .inner {
      .heading {
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 2.4rem;
        letter-spacing: 1px;
        margin-bottom: 10px;
        width: 100%;
      }
      .description {
        word-wrap: break-word;
        width: 100%;
      }
    }
  }

  .figure {
    display: grid;
    grid-template-rows: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .inner {
      .grid {
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        margin-left: auto;
        margin-right: auto;

        .item {
          position: relative;
          height: 250px;
          width: 100%;
          padding-right: 10px;
        }
      }
      .caption {
        font-size: 1.2rem;
        line-height: 2.4rem;
        letter-spacing: 0.7px;
        word-wrap: break-word;
      }
    }
  }

  .add-item {
    width: 100%;
    min-height: 244px;
    display: flex;
    border-color: #e4e6ef;
    border-width: 2px !important;
    border-style: dashed !important;
    border-radius: 20px;
  }
}
