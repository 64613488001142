.plugin-extra-features {
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 20px 20px 20px 20px;

  .heading {
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 2.5rem;
    letter-spacing: 1px;
    margin-bottom: 30px;
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px 30px;

    .card {
      .card__figure {
        height: 266px;
        width: 100%;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 10px;
        margin-bottom: 45px;
        background-color: #eff5ff;
      }

      .card__heading {
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.5rem;
        letter-spacing: 1px;
      }
    }

    .card__description {
      font-size: 1.4rem;
      line-height: 1.4rem;
      word-wrap: break-word;
    }

    .content-action-container {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 1.25rem;

      .content-action {
        margin-right: 15px;
      }
    }
  }
}
