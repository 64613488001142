.home-hero-wide-buttons {
  margin-top: 0;
  margin-bottom: 0;
  position: relative;

  .grid {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-rows: auto;

    .grid__inner {
      .hero {
        overflow: hidden;
        z-index: 1;
        border-radius: 20px;
        position: relative;
        padding-left: 45px;
        padding-right: 45px;
        height: 450px;
        background-color: var(--primaryColor);

        .hero__container {
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          width: 100%;
          height: 100%;
          align-items: center;

          .hero__content {
            padding-bottom: 15px;
            grid-column: 1 / span 5;
            width: 100%;
            z-index: 1;
            position: relative;

            .title {
              display: flex;
              flex-direction: column;
              width: 100%;
              gap: 15px;
              font-weight: 600;
              font-size: 1.2rem;
              line-height: 1rem;
              word-wrap: break-word;
              color: var(--secondaryColor);
            }

            .heading {
              font-family: var(--fontFancy);
              font-weight: 900;
              font-size: 2.4rem;
              line-height: 2.4rem;
              margin-bottom: 20px;
              color: #fff;
              width: 100%;
            }

            .description {
              display: flex;
              flex-direction: column;
              width: 100%;
              gap: 15px;
              font-size: 1.2rem;
              line-height: 1.2rem;
              letter-spacing: 0.25px;
              word-wrap: break-word;
              color: #fff;
              font-family: $regular;
            }

            .hero__cta {
              justify-content: space-between;
              gap: 15px;
              flex-wrap: wrap;
              display: flex;
              margin-top: 55px;

              .content-button__inner {
                background-color: $secondary1;
                color: $primary2;
                font-family: $regular;
              }
            }
          }

          .hero__usps {
            padding-bottom: 80px;
            grid-column: 11 / span 2;
            justify-self: flex-end;
            align-self: flex-end;
            bottom: 55px;
            gap: 15px;
            flex-direction: column;
            display: flex;

            z-index: 1;

            .hero__usp {
              padding-left: 0;
              padding-right: 0;
              justify-content: flex-end;
              width: 100%;

              background-color: transparent;
              border-width: 0 0 2px;
              border-style: solid;
              padding: 0;
              border-radius: 0;
              gap: 15px;

              flex-direction: row-reverse;

              font-size: 10px;

              display: flex;
              font-weight: 600;
              flex-wrap: nowrap;
              align-items: center;
              cursor: pointer;
              line-height: 20px;

              white-space: nowrap;
              box-shadow: 0 0 0 0 transparent;
              transition: background-color 0.2s ease-in-out,
                border 0.2s ease-in-out, color 0.2s ease-in-out,
                fill 0.2s ease-in-out;

              border-color: var(--primaryColorDarker);
              fill: var(--secondaryColor);
              color: #fcfcfc;

              .button__holder {
                margin-right: 0 !important;
                padding-right: 0;
                width: 16px;
                height: 28px;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .icon {
                min-width: 20px;
                width: 15px !important;
                height: 15px !important;
                transform: rotate(90deg) !important;
              }

              .title {
                font-weight: 700;
                font-size: 1rem;
                line-height: 1rem;
                letter-spacing: 0.7px;
                color: #fcfcfc;
                width: 100%;
                margin-right: 20px;
              }
            }
          }

          .hero__figure {
            top: 0;
            width: 47%;

            right: 20%;
            bottom: 0;
            position: absolute;

            .content-image {
              overflow: hidden;
              border-radius: 1.5rem;

              img {
                object-fit: cover;
                display: inline-block;
                max-width: 100%;
                vertical-align: top;
              }
            }
          }
        }
      }
    }
  }
}
