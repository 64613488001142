.home-hero-wide-three-icons {
  margin-top: 0;
  margin-bottom: 0;
  position: relative;

  .grid {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-rows: auto;

    .grid__inner {
      .hero {
        overflow: hidden;
        z-index: 1;
        border-radius: 20px;
        position: relative;
        padding-left: 45px;
        padding-right: 45px;
        height: 450px;
        background-color: var(--primaryColor);

        .hero__container {
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          width: 100%;
          height: 100%;
          align-items: center;

          .hero__content {
            padding-bottom: 15px;
            grid-column: 1 / span 5;
            width: 100%;
            z-index: 1;
            position: relative;

            .title {
              display: flex;
              flex-direction: column;
              width: 100%;
              gap: 15px;
              font-weight: 600;
              font-size: 1.2rem;
              line-height: 1rem;
              word-wrap: break-word;
              color: var(--secondaryColor);
            }

            .heading {
              font-family: var(--fontFancy);
              font-weight: 900;
              font-size: 2.4rem;
              line-height: 2.4rem;
              margin-bottom: 20px;
              color: #fff;
              width: 100%;
            }

            .description {
              display: flex;
              flex-direction: column;
              width: 100%;
              gap: 15px;
              font-size: 1.2rem;
              line-height: 1.2rem;
              letter-spacing: 0.25px;
              word-wrap: break-word;
              color: #fff;
              font-family: $regular;
            }

            .hero__cta {
              justify-content: space-between;
              gap: 15px;
              flex-wrap: wrap;
              display: flex;
              margin-top: 55px;

              .content-button__inner {
                background-color: $secondary1;
                color: $primary2;
                font-family: $regular;
              }
            }
          }

          .hero__usps {
            padding-top: 100px;
            grid-column: 10 / span 3;
            position: relative;
            align-self: flex-start;
            z-index: 1;
            gap: 25px;
            flex-direction: column;
            display: flex;

            .hero__usp {
              gap: 25px;
              align-items: center;
              display: flex;

              .icon__outer {
                width: 48px;
                min-width: 48px;
                height: 48px;
                justify-content: center;
                align-items: center;
                display: flex;
                border-radius: 10px;
                fill: var(--secondaryColor);
                background-color: var(--primaryColorDark);

                .icon {
                  min-width: 24px;
                  width: 24px;
                  height: 24px;
                }
              }

              .title {
                font-weight: 700;
                font-size: 1rem;
                line-height: 1rem;
                letter-spacing: 0.7px;
                color: #fcfcfc;
                width: 100%;
              }
            }
          }

          .hero__figure {
            top: 0;
            width: 47%;

            right: 20%;
            bottom: 0;
            position: absolute;

            .content-image {
              overflow: hidden;
              border-radius: 1.5rem;

              img {
                object-fit: cover;
                display: inline-block;
                max-width: 100%;
                vertical-align: top;
              }
            }
          }
        }
      }
    }
  }
}
