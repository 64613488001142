.blog-content-image {
    width: 100%;

    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    display: grid;
    grid-template-columns: repeat(12,1fr);

    .blog-content {
        width: 100%;

        grid-column: 2/span 10;

        input {
            color: inherit;
        }

        .blog-content__figure {
            overflow: hidden;
            border-radius: 1.5rem;

            img {
                width: 100%;
                height: 34rem;
                object-fit: cover;
            }
        }

        .blog-content__caption {
            width: 100%;
            margin-top: 0.25rem;
        }
    }
}
