.home-reviews-single {
  position: relative;
  width: 100%;
  border-radius: 20px;
  background-color: var(--secondaryColor);
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 15px;
  padding-right: 15px;

  .reviews__header {
    display: grid;
    grid-template-columns: 40% 60%;

    .reviews__content {
      z-index: 1;
      position: relative;

      .reviews__heading {
        font-family: var(--fontFancy);
        font-weight: 900;
        font-size: 2.1rem;
        line-height: 2.1rem;
        margin-bottom: 25px;
        color: $primary1 !important;
      }

      .reviews__description {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
        word-wrap: break-word;
        margin-bottom: 20px;
      }

      .reviews__rating {
        gap: 10px;
        align-items: center;
        display: flex;
        margin-bottom: 30px;
        width: 100%;

        .rating__stars {
          gap: 10px;
          display: flex;
          fill: var(--primaryColor);
          width: 100%;
          flex-direction: row !important;

          .icon {
            min-width: 22px;
            width: 22px;
            height: 22px;
          }
        }

        .rating__score {
          font-weight: 700;
          line-height: 1.9rem !important;
          font-size: 1.2rem;
          letter-spacing: 0.25px;
          color: $primary1;
        }
      }
    }

    .reviews__figure {
      max-height: 420px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  .reviews__container {
    align-items: center;
    justify-content: center;
    display: flex;
    .reviews__slider {
      -webkit-tap-highlight-color: transparent;
      box-shadow: 0px 32px 64px rgb(17 17 17 / 8%);
      position: relative;
      border-radius: 40px;
      background-color: white;
      width: 80%;

      .viewport {
        height: 300px;
        touch-action: pan-y;
        overflow: hidden;
        position: relative;

        .slider {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0px;
          transform: translateX(50%);

          .slide {
            position: absolute;
            left: 0px;
            transform: translateX(-50%);
            opacity: 1;

            .rating {
              justify-content: center;
              gap: 10px;
              align-items: center;
              display: flex;
              margin-bottom: 25px;
              margin-top: 40px;

              .rating__stars {
                gap: 10px;
                display: flex;
                fill: #ffb800;
                justify-content: center;

                .icon {
                  min-width: 27px;
                  width: 27px !important;
                  height: 27px !important;
                  fill: #ffb800;
                }
              }
            }

            .heading {
              font-weight: 900;
              font-size: 2.1rem;
              line-height: 2.1rem;
              margin-bottom: 25px;
              font-family: $regular;
              display: flex;
              justify-content: center;
            }

            .description {
              display: flex;
              flex-direction: column;
              width: 100%;
              gap: 15px;
              font-size: 1.2rem;
              line-height: 1.2rem;
              letter-spacing: 0.25px;
              word-wrap: break-word;
              margin-bottom: 30px;
              color: $primary1;
              font-family: $regular;
              align-items: center;
              text-align: center;
              padding-left: 25px;
              padding-right: 25px;
            }

            .author {
              display: flex;
              flex-direction: column;
              width: 100%;
              gap: 30px;
              font-size: 1rem;
              line-height: 1rem;
              letter-spacing: 0.25px;
              word-wrap: break-word;
              margin-bottom: 30px;
              color: $primary1;
              font-family: $regular;
              align-items: center;
            }
          }
        }
      }

      .button__prev {
        left: -27px;
        transform: translate(-50%, -50%);
        opacity: 0.3 !important;
        cursor: auto;
        background-color: var(--primaryColorLight) !important;
        fill: var(--primaryColorDarker) !important;

        position: absolute;
        background: hsla(0deg, 0%, 100%, 0.75);
        border: none;
        background-color: var(--primaryColor);
        fill: var(--primaryColorLight);
        transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out,
          fill 0.3s ease-in-out;
        border-radius: 8px;
        top: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;

        .icon {
          fill: inherit;
          width: 15px;
          height: 15px;
        }
      }

      .button__next {
        right: -27px;
        transform: translate(50%, -50%);
        opacity: 0.3 !important;
        cursor: auto;
        background-color: var(--primaryColorLight) !important;
        fill: var(--primaryColorDarker) !important;

        position: absolute;
        background: hsla(0deg, 0%, 100%, 0.75);
        border: none;
        background-color: var(--primaryColor);
        fill: var(--primaryColorLight);
        transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out,
          fill 0.3s ease-in-out;
        border-radius: 8px;
        top: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;

        .icon {
          fill: inherit;
          width: 15px;
          height: 15px;
        }
      }

      .page-dots {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        gap: 5px;
        bottom: 16px;
        list-style: none;
        line-height: 1;

        .dot {
          position: relative;
          transition: width 0.3s ease-in-out;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: $primary1;
        }

        .dot.is-selected {
          width: 25px;
        }
      }
    }
  }
}
