.team-employees {
  position: relative;
  width: 100%;

  .content {
    .title {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 100%;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 2.2rem;
      letter-spacing: 0.25px;
      word-wrap: break-word;
      margin-bottom: 10px;
      color: var(--primaryColorDark);
    }

    .heading {
      font-family: var(--fontFancy);
      font-weight: 900;
      font-size: 2.8rem;
      line-height: 3rem;
      color: var(--primaryColorDark);
    }
  }

  .employees {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .employee {
      position: relative;

      .item {
        position: relative;
        width: 180px;
        height: 400px;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .item-figure {
          width: 100%;
          height: 400px;
          object-fit: cover;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 20px;
        }

        .item-content {
          z-index: 1;

          .item-content__name {
            display: block;
            color: var(--primaryColorDark);
          }

          .item-content__department {
            display: block;
            color: #fff;
            font-weight: 700;
          }
        }
      }
    }
  }

  .department {
    background-color: var(--secondaryColorLighter);
    border-radius: 20px;
    gap: 25px;
    display: flex;
    padding: 110px 80px 60px 50px;
    margin-top: -50px;
    opacity: 1;

    .icon {
      margin-top: 40px;
      height: 60px;
      min-width: 60px;
      width: 60px;
      fill: #ef5da8;
      color: #f4c2dc;
    }

    .content {
      flex-grow: 1;

      .heading {
        font-weight: 700;
        margin-bottom: 15px;
        font-size: 2.2rem;
        line-height: 2.8rem;
        margin-bottom: 15px;
      }

      .description {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
        letter-spacing: 0.25px;
        font-size: 1.4rem;
        line-height: 1.8rem;
        word-wrap: break-word;
        color: var(--primaryColorDark);
      }
    }
  }

  .add-item {
    width: 100%;
    min-height: 270px;
    height: 100%;
    display: flex;
    border-color: #e4e6ef;
    border-width: 2px !important;
    border-style: dashed !important;
    border-radius: 20px;
  }
}
