.home-showcase-box-highlight-left {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-rows: auto;
  background-color: var(--primaryColor);
  padding: 10px;
  border-radius: 1.5rem;

  .showcase {
    width: 100%;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 30px;

    .showcase__content {
      grid-column: 9 / span 4;
      order: 2;
      grid-row: 1;
      width: 100%;

      .header-title_input {
        font-family: $fancy;
        font-weight: 900;
        font-size: 2.8rem;
        line-height: 2.8rem;
        margin-bottom: 15px;
        width: 100%;
        color: white;
      }

      .description_input {
        margin-bottom: 25px;
        width: 100%;
        color: white;
        line-height: 2rem;
        font-family: $regular;
      }

      .content-button__inner {
        background-color: $secondary1;
        color: $primary2;
        font-family: $regular;
      }

      .text-primary-color {
        color: $primary2 !important;
        font-weight: 600;
      }
    }
  }

  .showcase__figure {
    grid-column: 1 / span 8;
    order: 1;
    grid-row: 1;
    width: 400;
    height: 450;

    .content-image {
      overflow: hidden;
      border-radius: 1.5rem;

      img {
        object-fit: cover;
        display: inline-block;
        max-width: 100%;
        vertical-align: top;
      }
    }
  }
}
