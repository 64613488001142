.table-two-columns {
  position: relative;
  display: block;

  .heading {
    font-family: var(--fontFancy);
    font-weight: 900;
    font-size: 2.4rem;
    line-height: 2.8rem;
    width: 100%;
    color: var(--primaryColor);
  }

  .table-two {
    margin-bottom: 20px;
    width: 100%;
    position: relative;

    .text {
      color: var(--primaryColor);
      width: 100%;
    }

    .headtext {
      font-weight: 600;
    }

    .text-right {
      text-align: right;
    }

    li {
      &:first-child {
        width: 30%;
      }
      &:nth-child(2) {
        width: 70%;
      }
      color: var(--primaryColor);
    }

    dd {
      &:first-of-type,
      &:first-child {
        width: 30%;
      }

      &:nth-of-type(2) {
        width: 70%;
      }
      color: var(--primaryColor);
    }

    ol,
    dd,
    dt {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .tablehead {
      li {
        padding-top: unset;
      }
    }

    .tablecontent {
      li,
      .item {
        font-weight: 600;
        float: left;

        &:not(:first-child) {
          text-align: right !important;
        }
      }

      dd {
        &:not(:first-of-type) {
          text-align: right;
        }
      }

      dt,
      dd,
      li {
        padding-bottom: 10px;
        padding-top: 10px;
      }
    }

    .tablebody {
      dt {
        display: none;
      }
      .tablerow {
        border-color: #d2d3ff;
        border-style: solid;
        border-width: 0 0 1px;
        margin: 0;
        position: relative;

        &:first-child {
          border-top-width: 4px;
        }
      }
    }

    .tablerow {
      display: flex;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    word-wrap: break-word;
    color: var(--primaryColor);
  }

  .add-item {
    width: 100%;
    margin-top: 10px;
    min-height: 55px;
    display: flex;
    border-color: #e4e6ef;
    border-width: 2px !important;
    border-style: dashed !important;
    border-radius: 20px;
  }
}
