.case-project-highlights {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(12, 1fr);

  .case-customer-question__content {
    grid-column: 2 / span 10;

    .content-title {
      width: 100%;
      font-size: 2rem;
      font-weight: 700;
      color: var(--primaryColor);
    }

    .content-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0.5rem;

      padding: 0;
      margin: 0;
      margin-top: 2.5rem;

      .list-item {
        position: relative;

        border-radius: 1.25rem;
        padding: 2.25rem;

        .item-labels {
          position: absolute;

          left: 2rem;
          top: 0;
          transform: translateY(-50%);

          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;

          .labels-item {
            border-radius: 2px;

            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-bottom: 0.125rem;

            background-color: var(--secondaryColor);

            .labels-item__title {
              font-size: 0.75rem;
              font-weight: 700;
              text-transform: uppercase;

              line-height: 2.2rem !important;

              color: var(--primaryColor);
            }
          }
        }

        .item-title {
          width: 100%;
          font-size: 1.25rem;
          font-weight: 700;
          color: var(--primaryColor);
        }

        .item-description {
          width: 100%;
          color: var(--primaryColor);
        }
      }
    }
  }
}
