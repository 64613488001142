// - background
// ----------------------------------------------------------
.bg-white {background-color:$white}
.bg-black {background-color:$black}
.bg-gray7 {background-color:$gray7}
.bg-gray8 {background-color:$gray8}
.bg-black1 {background-color:$black1}

.bg-primary1 {background-color:$primary1}
.bg-primary2 {background-color:$primary2}
.bg-primary3 {background-color:$primary3}
.bg-primary4 {background-color:$primary4}
.bg-primary5 {background-color:$primary5}

.bg-secondary1 {background-color:$secondary1}
.bg-secondary2 {background-color:$secondary2}
.bg-secondary3 {background-color:$secondary3}
.bg-secondary4 {background-color:$secondary4}
.bg-secondary5 {background-color:$secondary5}

.bg-red1 {background-color:$red1;}
//.back-gray1 {background-color:$gray1; color:$black1; fill:$black1;}
//.back-gray2 {background-color:$gray2; color:$black1;fill:$black1;}
//.bg-black2 {background-color:$black2;;}

.bg-green1 {background-color:$green1}
.bg-purple1 {background-color:$purple1}
.bg-pink1 {background-color:$pink1}
.bg-yellow1 {background-color:$yellow1}

// - color 
// ----------------------------------------------------------
.text-white {color:$white}
.text-gray8 {color:$gray8}

.text-primary1 {color:$primary1}
.text-primary2 {color:$primary2}
.text-primary3 {color:$primary3}
.text-primary4 {color:$primary4}
.text-primary5 {color:$primary5}

.text-secondary1 {color:$secondary1}
.text-secondary2 {color:$secondary2}
.text-secondary3 {color:$secondary3}
.text-secondary4 {color:$secondary4}
.text-secondary5 {color:$secondary5}

.text-succes {color:$green1}
.text-green5 {color:$green5}
.text-purple5 {color:$purple5}
.text-pink5 {color:$pink5}
.text-yellow5 {color:$yellow5}

// - fill 
// ----------------------------------------------------------
.fill-white {fill:$white}
.fill-gray8 {fill:$gray8}

.fill-primary1 {fill:$primary1}
.fill-primary2 {fill:$primary2}
.fill-primary3 {fill:$primary3}
.fill-primary4 {fill:$primary4}
.fill-primary5 {fill:$primary5}

.fill-secondary1 {fill:$secondary1}
.fill-secondary2 {fill:$secondary2}
.fill-secondary3 {fill:$secondary3}
.fill-secondary4 {fill:$secondary4}
.fill-secondary5 {fill:$secondary5}

.fill-green1 {fill:$green1}
.fill-purple1 {fill:$purple1}
.fill-pink1 {fill:$pink1}
.fill-yellow1 {fill:$yellow1}

// - border 
// ----------------------------------------------------------
.border-trans {border-color:transparent}
.border-white {border-color:$white}
.border-gray2 {border-color:$gray2}
.border-gray8 {border-color:$gray8}

.border-primary1 {border-color:$primary1}
.border-primary2 {border-color:$primary2}
.border-primary3 {border-color:$primary3}
.border-primary4 {border-color:$primary4}
.border-primary5 {border-color:$primary5}

.border-secondary1 {border-color:$secondary1}
.border-secondary2 {border-color:$secondary2}
.border-secondary3 {border-color:$secondary3}
.border-secondary4 {border-color:$secondary4}
.border-secondary5 {border-color:$secondary5}