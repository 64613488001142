.quote-block {
  background-color: var(--primaryColorLighter);
  border-radius: 20px;
  position: relative;
  padding: 15px;

  .quote {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    color: var(--primaryColor);
  }

  .footer {
    font-family: var(--fontFancy);
    font-weight: 900;
    margin-top: 12px;
    color: var(--primaryColor);
  }
}
