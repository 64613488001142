.blog-content-info-panel {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(12,1fr);

    .blog-content {
        width: 100%;

        color: #fff;

        grid-column: 2/span 10;

        border-radius: 1.25rem;
        padding: 3.25rem 3rem 2.75rem;

        background-color: $primary2;

        .ck-editor__main {
            &:hover {
                cursor: auto !important;
            }
        }
    }
}
