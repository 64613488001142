// enable/disable compiling of card scss
$card: true;

@if $card == true {

	.card__image {
		width:100%;
		height:auto;
	}

	.card__winner {
		padding:7px 15px;
		letter-spacing: 0.8px;
		top:0;
		transform:translateY(-50%);
		left:45px;
		white-space: nowrap;
		display: inline-block;

	}

	// - scale
	// -----------------------------------------------------------------------------
	.card--scale {
		.card__inner {
			transition:transform .2s ease-in-out;
			// hover state located in accessibility
		}
	}

	// - advantage
	// -----------------------------------------------------------------------------
	.card--advantage {
		padding-top:42px;
		transition: transform .6s ease-in-out;

		.card__inner {
			padding:42px 30px 50px;
			transition:
				background-color .2s ease-in-out, 
				color .2s ease-in-out,
		}

		.card__holder {
			width:60px;
			height:60px;
		}

		.card__heading {
			font-size: clamp(3.2rem, 3vw + 1rem, 4rem);
			line-height: clamp(3.6rem, 3vw + 1rem, 4.4rem);
			letter-spacing: -2px;
		}

		.card__swoop {
			left:100%;
			bottom:0;
			top:-42px;
			z-index: -1;
			transition:transform .3s ease-in-out;
			transition-delay: .2s;
		}
	}



	// - review
	// -----------------------------------------------------------------------------
	.card--review {
		break-inside: avoid;
		
		&:not(:first-child) {
			margin-top:30px;
		}
	}

		// - portfolio
	// -----------------------------------------------------------------------------
	.card--portfolio {
		grid-column:span 6;

		@media (min-width:$screen-large) {
			grid-column: span 2;
		}

		&:nth-child(-n+3) {
			grid-column:span 6;
		}

		.card__content {
			margin-top:30px;
		}

		.card__image {
			width:100%;
			height:100%;
			object-fit: cover;
			object-position: center;
		}

		// first
		@media (min-width:$screen-large) {
			&:nth-child(1) {
				.card__inner {
					display: grid;
					grid-template-columns: repeat(12, minmax(0, 1fr));

					.card__figure {
						grid-column: span 12;
					}

					.card__content {
						margin-top:40px;
						grid-column: 2 / span 10;
					}
				}
			}
		}

		// 2nd and 3rd
		&:nth-child(3),
		&:nth-child(2) {
			@media (min-width:$screen-medium) {
				.card__inner {
					display: flex;
					gap:50px;
					align-items: center;

					.card__content {
						margin-top:0;
						max-width:33.33%;
					}
				}
			}

			// 2nd
			@media (min-width:$screen-medium) {
				&:nth-child(2) {
					.card__content {
						padding-right:42px;
					}
				}

				// 3rd
				&:nth-child(3) {
					.card__inner {
						flex-direction: row-reverse;
					}
					.card__content {
						padding-left:40px;
					}
				}
			}
		}

		// after 3
		&:nth-child(n+4) {
			display: flex;
			flex-direction: column;

			@media (min-width:$screen-medium) {
				.card__image {
					min-height:474px;
				}
			}

			.card__content {
				display: flex;
				flex-grow: 1;
				flex-direction: column;
			}

			&:nth-child(4n+6),
			&:nth-child(4n+5) {
				grid-column: span 6;

				@media (min-width:$screen-large) {
					grid-column: span 4;
				}
			}

			@media (min-width:$screen-large) {
				&:nth-child(4n+4),
				&:nth-child(4n+7) {
					.prose {
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}
				}
			}

		}
	}

	// - process
	// -----------------------------------------------------------------------------
	.card--process {
		padding:38px;

		.card__tags {
			position: absolute;
			top:0;
			transform:translateY(-50%);
		}

		@media (min-width:$screen-medium) and (max-width:$screen-medium-large-max) {
			&:nth-child(3) {
				grid-column:span 2;
			}
		}
	}

	// - package
	// -----------------------------------------------------------------------------
	.card--package {
		padding:36px 25px 46px 25px;

		@media (min-width:$screen-large) {
			padding:36px 30px 46px 36px;
		}

		.card__holder {
			width:70px;
			min-width:70px;
			height:70px;
		}

		@media (min-width:$screen-small) and (max-width:$screen-small-max) {
			&:last-child {
				grid-column: 1 / span 2;
			}
		}

		.card__subtitle {
			white-space: nowrap;
		}
	
		.card__price {
			font-size:5.2rem;
			line-height:5.8rem;
			small {
				font-size:4.4rem;
				line-height:5.8rem;
			}
			span {
				font-size:1.6rem;
				line-height:2.8rem;
			}
		}
	}

	.card--package-best {
		padding:36px 25px 46px 25px;

		@media (min-width:$screen-large) {
			padding:36px 30px 46px 45px;
		}
	}

	// - USP
	// -----------------------------------------------------------------------------
	.card--usp {
		.card__figure {
			width:100%;
			//height:266px;
		}
	}

	// - Customer
	// -----------------------------------------------------------------------------
	.brand {
	padding:15px;
	display: flex;
	align-items: center;
	justify-content: center;
	height:100%;

		@media (min-width:$screen-small) {
			min-height:125px;
			margin-right:15px;
			width:200px;
			padding:0 20px;
		}
	}

	.brand__image {
		width:100%;
		height:auto;
	}


	// - Highlight
	// -----------------------------------------------------------------------------
	.card--highlight {
		
		
		.card__inner {
			padding:46px 62px 38px 33px;
			box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.06);
		}

		.card__tags {
			top:0;
			left:33px;
			transform:translateY(-50%);
		}

		@media (min-width:$screen-small) and (max-width:$screen-medium-max) {
			&:nth-child(odd):last-child {
				grid-column:span 2;
			}
		}
	}

	.card--featured-employee {
		width:270px;

		@media (min-width:$screen-small) {
			width:290px;
		}
	}

	.card.card--offset {
		padding-top:50px;
		padding-bottom:50px;

		&:nth-child(4n+1) {
			padding-top:35px;
			padding-bottom:65px;
		}

		&:nth-child(4n+2) {
			padding-top:70px;
			padding-bottom:30px;
		}

		&:nth-child(4n+3) {
			padding-top:30px;
			padding-bottom:70px;
		}

		&:nth-child(4n+4) {
			padding-top:60px;
			padding-bottom:40px;
		}
	}


	// - Employee
	// -----------------------------------------------------------------------------
	.card--featured-employee {
		.card__figure {
			background-color:#ccc;
			border-radius:21px;
		}

		.card__image {
			transition: opacity 0.4s;
			//opacity: 0;

			&.flickity-lazyloaded {
				opacity:1;
			}
		}
	}

	.card--appear {
		&.hidden {
			height: 1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			width: 1px;
			z-index: -1;
		}

		transition-delay: calc(.05s*var(--index));
		transition-duration: .2s;
		transition-property: opacity;
		transition-timing-function: ease-in-out;
		opacity: 1;
	}

	.card--employee {
		.card__inner {
			filter: 
				drop-shadow(0 0px 18px rgba(0,0,0,.05))
		}

		.card__figure {
			&::before {
				content:'';
				position: absolute;
				top:0;
				right: 0;
				bottom:0;
				left:0;
				background: linear-gradient(15deg, rgba(0,0,0,0.30) 0%, rgba(255,255,255,0) 50%);
				border-radius:21px;
			}
		}

		.card__content {
			bottom:28px;
			left:28px;
			right:28px
		}
	}


	


	.card__video {
		width:102px;
		height:102px;
	}

	// - special
	// -----------------------------------------------------------------------------

	.card--special {
		background-color: $gray5;
		transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;

		.card__content {
			padding: 16px 26px 22px;
		}
	}


	//  - Flickity
	// flickity-check
	// .flickity-enabled {
	// 	.card {
	// 		margin-right:15px;
	// 		padding-top:10px;
	// 		padding-bottom:10px;
	// 	}
	// }

	// - Project
	// - uses flickity
	// -----------------------------------------------------------------------------
	
	// flickity-check
	// .flickity-enabled {
	// 	.card__image {
	// 		width:100%;
	// 		height:auto;
	// 	}
	// }

	.card--project {
		.card__media {
			top:50%;
			transform:translateY(-50%);
			
			filter: drop-shadow(0px 24px 38px rgba(30, 23, 84, 0.14)) drop-shadow(0px 9px 46px rgba(30, 24, 86, 0.15)) drop-shadow(0px 11px 15px rgba(30, 24, 85, 0.2));

			@media (max-width:$screen-medium-max) {
				transform:translateY(-50%) scale(0.8);
			}

			@media (min-width:$screen-medium-large) {
				right:-16px;
			}

			@media (min-width:$screen-large) {
				right:0;
			}
		}

		@media (max-width:1059px) {
			.card__image {
				max-height:397px;
				width:auto;
				height:100%;
			}
		}
	}

	.card--project-has-media {
		@media (min-width:$screen-medium-large) {
			.card__image {
				height:100%;
				object-fit: cover;
				object-position: center;
				width:calc(100% - 33px);
			}
		}

		@media (min-width:$screen-large) {
			.card__image {
				width:calc(100% - 40px);
			}
		}
	}

	// - Cases
	// - uses flickity
	// -----------------------------------------------------------------------------
	// flickity-check
	// .flickity-enabled {
	// 	.card--cases {
	// 		width:350px;
	// 	}
	// }

	.card--cases {
		.card__content {
			bottom:23px;
			left:23px;
			right:23px;
			padding:26px 23px 23px;
		}

		.card__tags {
			top:0;
			left:23px;
			transform:translateY(-50%);
		}
	}


	// - blog
	// - uses flickity
	// -----------------------------------------------------------------------------
	// flickity-check
	// .flickity-enabled {
	// 	.card--blog {
	// 		margin-right: 25px;
	// 		width: 300px;
	// 	}
	// }




	//.card--blog {}

	// - Plugin
	// - uses flickity
	// -----------------------------------------------------------------------------
	// flickity-check
	// .flickity-enabled {
	// 	.card--plugin {
	// 		width:305px;
	// 		padding-bottom:15px;
	// 	}
	// }

	.card--plugin {
		.card__inner {
			padding:20px;
		}

		.card__holder {
			
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&.card--fixed {
			width:100%;
			max-width:270px;
			padding-top:70px;
			padding-bottom:0;
			margin-left:auto;
			margin-right:auto;
		}

		&.card {
			&.card--icon-outside {
				padding-top:115px;
			}
		}
	}


	.card--icon-outside {
		&.card {
			.card__inner {
				padding-top:115px;
			}
		}

		.card__holder {
			transform:translate(-50%, -50%);
			left:50%;
			top:0;
			position: absolute;
			width:145px;
			height:145px;
			filter: drop-shadow(0px 6px 13px rgba(17, 17, 17, 0.06));
		}
	}

	.card--icon-inside {
		.card__holder {
			width:150px;
			height:150px;
			margin-left:auto;
			margin-right:auto;
		}
	}

	// - Support
	// - uses flickity
	// -----------------------------------------------------------------------------
	// flickity-check
	// .flickity-enabled {
	// 	.card--support {
	// 		width:276px;
	// 	}
	// }

	.card--support {
		.card__content {
			padding:38px 26px 14px;
			border-radius:0 0 16px 16px;
		}

		.card__figure {
			width:100%;
			height:185px;
			box-shadow:0 2px 1px 0 rgba($black, 0.06);
		}

		.card__tag {
			top:0;
			left:26px;
			transform:translateY(-50%);
			padding:5px 9px;
		}
	}


	// - Addon
	// - uses flickity
	// -----------------------------------------------------------------------------
	// flickity-check
	// .flickity-enabled {
	// 	.card--addon {
	// 		width:250px;
	// 		min-height:280px;
	// 		margin-top:12px;
	// 	}
	// }
	
	.card--addon {
		.card__inner {
			padding:38px 20px 20px;
		}

		.card__tag {
			top:0;
			left:20px;
			transform:translateY(-50%);
			padding:5px 9px;
		}
	}
}
