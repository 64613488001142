.heading-description {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .title {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 2.2rem;
    letter-spacing: 0.25px;
    word-wrap: break-word;
    margin-bottom: 10px;
    color: var(--primaryColorDark);
  }

  .heading {
    font-family: var(--fontFancy);
    font-weight: 900;
    font-size: 2.8rem;
    line-height: 2.8rem;
    margin-bottom: 30px;
    color: var(--primaryColorDark);
    text-align: center;
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.8rem;
    word-wrap: break-word;
    color: var(--primaryColorDark);
    text-align: center;
  }
}
