.blog-content-description {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(12,1fr);

    .blog-content {
        width: 100%;

        grid-column: 3/span 8;

        .blog-content__title {
            width: 100%;
            font-size: 2rem;
            font-weight: 700;
            color: $primary1;
        }

        .ck-editor__main {
            &:hover {
                cursor: auto !important;
            }
        }
    }
}