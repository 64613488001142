.home-six-icons-with-titles {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-rows: auto;

  .usp {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    .usp__item {
      gap: 15px;
      align-items: center;
      display: flex;

      .icon__outer {
        width: 38px;
        min-width: 38px;
        height: 38px;

        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 10px;
        fill: var(--secondaryColor);
        background-color: var(--primaryColor);

        .icon {
          min-width: 24px;
          width: 24px;
          height: 24px;

          fill: var(--secondaryColor);
        }
      }

      .heading {
        font-family: var(--fontRegular);
        font-weight: 700;
        font-size: 1rem;
        line-height: 1rem;
        letter-spacing: 0.7px;

        color: $primary1;

        width: 100%;
      }

      .description {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 15px;

        font-family: $regular;
        color: $primary1;

        word-wrap: break-word;
      }
    }
  }
}
