.archive-reviews {
  width: 100%;
  background-color: var(--secondaryColor);
  padding: 10px;
  border-radius: 20px;
  .content {
    width: 100%;
    .heading {
      font-family: var(--fontFancy);
      font-weight: 900;
      font-size: 2.8rem;
      line-height: 3.2rem;
      margin-bottom: 10px;
      color: var(--primaryColor);
      width: 100%;
    }

    .description {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 100%;
      word-wrap: break-word;
      margin-bottom: 25px;
      color: var(--primaryColor);
      font-family: var(--fontRegular);
      width: 100%;
      font-size: 1.4rem;
    }

    .rating {
      gap: 10px;
      align-items: center;
      display: flex;
      margin-bottom: 30px;
      width: 100%;

      .rating__stars {
        gap: 10px;
        display: flex;
        fill: var(--primaryColor);
        width: 100%;
        flex-direction: row !important;

        .icon {
          min-width: 22px;
          width: 22px;
          height: 22px;
        }
      }

      .rating__score {
        font-weight: 700;
        line-height: 1.9rem !important;
        font-size: 1.2rem;
        letter-spacing: 0.25px;
        color: $primary1;
      }
    }
  }

  .reviews {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    .review {
      display: flex;
      border-radius: 20px;
      background-color: #fff;
      padding: 20px;
      justify-content: center;
      align-items: center;
      height: 150px;

      text-align: center;
    }
  }
}
