.info-block-expand {
  width: 100%;
  padding: 5.25rem 4.25rem;

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .title {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 15px;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1rem;
      word-wrap: break-word;
      color: $primary1;
    }

    .heading {
      font-family: var(--fontFancy);
      font-weight: 900;
      font-size: 2.8rem;
      line-height: 2.8rem;
      margin-bottom: 25px;
      color: $primary1;
    }

    .description {
      width: 100%;
      color: $primary1;
      font-weight: 600;
    }

    .content {
      width: 100%;
      color: $primary1;
    }
  }
}
