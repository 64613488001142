// enable/disable compiling of icon scss
$icon: true;

@if $icon == true {
	// icon language: https://polaris.shopify.com/content/grammar-and-mechanics#active-and-passive-voice

	// - These elements are purely for testing, disable when done
	// ----------------------------------------------------------
	// .icons {
	// 	display: grid;
	// 	grid-template-columns:repeat(auto-fill, minmax(200px, 1fr));
	// 	grid-gap:20px;
	
	// 	code {
	// 		font-size:14px;
	// 	}
	// }

	// .icon {
	// 	margin:0 auto;
	// 	fill:blue;
	// 	color:red;
	// }

	// - Rotation for chevrons, angles, arrows, etc
	// ----------------------------------------------------------
	.rotate-90 {
		transform:rotate(90deg);
	}

	.rotate-180 {
		transform:rotate(180deg);
	}

	.rotate-270 {
		transform:rotate(270deg);
	}

	// - Sizes
	// ----------------------------------------------------------


	
	.icon-inherit {
		width: inherit;
		height: inherit;
	}

	[class*="icon-"] {
		display: block;
	}

	.icon-10 {min-width:10px;width:10px;height:10px}
	.icon-12 {min-width:12px;width:12px;height:12px}
	.icon-13 {min-width:13px;width:13px;height:13px}
	.icon-14 {min-width:14px;width:14px;height:14px}
	.icon-16 {min-width:16px;width:16px;height:16px}
	.icon-18 {min-width:18px;width:18px;height:18px}
	.icon-20 {min-width:20px;width:20px;height:20px}
	.icon-22 {min-width:22px;width:22px;height:22px}
	.icon-24 {min-width:24px;width:24px;height:24px}
	.icon-25 {min-width:25px;width:25px;height:25px}
	.icon-27 {min-width:27px;width:27px;height:27px}
	.icon-32 {min-width:32px;width:32px;height:32px}
	.icon-38 {min-width:38px;width:38px;height:38px}
	.icon-40 {min-width:40px;width:40px;height:40px}
	.icon-45 {min-width:45px;width:45px;height:45px}
	.icon-64 {min-width:64px;width:64px;height:64px}
	.icon-80 {min-width:80px;width:80px;height:80px}
	.icon-100 {min-width:100px;width:100px;height:100px}

	@media (max-width:$screen-tiny-max) {
		.icon-100 {min-width:80px;width:80px;height:80px}
	}
}