// - margin classes
// - sizes: global | tiny | small | medium | large | huge
// - examples:
// - all 		| .m10  | .xs-m10  | .sm-m10  | .md-m10  | .lg-m10  | .hg-m10
// - top 		| .m10t | .xs-m10t | .sm-m10t | .md-m10t | .lg-m10t | .hg-m10t
// - right 		| .m10r | .xs-m10r | .sm-m10r | .md-m10r | .lg-m10r | .hg-m10r
// - bottom 	| .m10b | .xs-m10b | .sm:m10b | .md-m10b | .lg-m10b | .hg-m10b
// - left 		| .m10l | .xs-m10l | .sm-m10l | .md-m10l | .lg-m10l | .hg-m10l
// - vertical 	| .m10x | .xs-m10x | .sm-m10x | .md-m10x | .lg-m10x | .hg-m10x
// - horizontal | .m10y | .xs-m10y | .sm-m10y | .md-m10y | .lg-m10y | .hg-m10y
// ---------------------------------------------------------------------------

// global - all
//.m10 {margin: 10px}
// global - top
.mat {margin-top:auto;}
.m5t {margin-top: 5px}
.m10t {margin-top: 10px}
.m15t {margin-top: 15px}
.m20t {margin-top: 20px}
.m25t {margin-top: 25px}
.m30t {margin-top: 30px}
.m35t {margin-top: 35px}
.m40t {margin-top: 40px}
// global - right
// global - bottom
.m5b {margin-bottom: 5px}
.m10b {margin-bottom: 10px}
.m15b {margin-bottom: 15px}
.m20b {margin-bottom: 20px}
.m25b {margin-bottom: 25px}
.m30b {margin-bottom: 30px}
.m35b {margin-bottom: 35px}
.m40b {margin-bottom: 40px}
.m80b {margin-bottom: 80px}
// global - left
.m10l {margin-left:10px}
// global - vertical
.mautox {margin-left:auto; margin-right:auto;}
// global - horizontal

// - small device
// ---------------------------------------------------------------------------

// mini | tiny
@media (max-width: $screen-tiny-max) {
	.sd\:mautox {margin-left:auto; margin-right:auto}
	// small - all
	// small - top
	.sd\:m30t {margin-top: 30px}
	.sd\:m40t {margin-top: 40px}
	.sd\:m45t {margin-top: 45px}
	.sd\:m50t {margin-top: 50px}
	// small - right
	// small - bottom
	.sd\:m20b- {margin-bottom: -20px}
	.sd\:m10b- {margin-bottom: -10px}
	.sd\:m10b {margin-bottom: 10px}
	.sd\:m20b {margin-bottom: 20px}
	.sd\:m25b {margin-bottom: 25px}
	.sd\:m30b {margin-bottom: 30px}
	.sd\:m35b {margin-bottom: 35px}
	.sd\:m40b {margin-bottom: 40px}
	.sd\:m50b {margin-bottom: 50px}
	.sd\:m55b {margin-bottom: 55px}
	.sd\:m60b {margin-bottom:60px}
	.sd\:m70b {margin-bottom:70px}
	.sd\:m100b {margin-bottom: 100px}
	// small - left
	// small - vertical
	.sd\:m10x- {margin-left:-10px; margin-right:-10px}
	.sd\:m20x- {margin-left:-20px; margin-right:-20px}
	// small - horizontal
}

// mini
@media (max-width: $screen-mini-max) {
	// mini - all
	// mini - top
	// mini - right
	// mini - bottom
	// mini - left
	// mini - vertical
	// mini - horizontal
}

// tiny
@media (min-width: $screen-tiny) and (max-width: $screen-tiny-max) {
	// tiny - all
	// tiny - top
	// tiny - right
	// tiny - bottom
	// tiny - left
	// tiny - vertical
	// tiny - horizontal
}

// - big device
// ---------------------------------------------------------------------------

// small | medium | large | huge
@media (min-width: $screen-small) {
	// big - all
	.bd\:m10 {margin: 10px}
	// big - top
	.bd\:m35t {margin-top:35px}
	.bd\:m45t {margin-top:45px}
	.bd\:m50t {margin-top:50px}
	.bd\:m55t {margin-top:55px}
	.bd\:m90t {margin-top:90px}
	.bd\:m120t {margin-top:120px}
	// big - right
	// big - bottom
	.bd\:m10b {margin-bottom: 10px}
	.bd\:m20b {margin-bottom: 20px}
	.bd\:m25b {margin-bottom: 25px}
	.bd\:m30b {margin-bottom: 30px}
	.bd\:m35b {margin-bottom: 35px}
	.bd\:m45b {margin-bottom: 45px}
	.bd\:m50b {margin-bottom: 50px}
	.bd\:m55b {margin-bottom: 55px}
	.bd\:m60b {margin-bottom: 60px}
	.bd\:m65b {margin-bottom: 65px}
	.bd\:m70b {margin-bottom: 70px}
	.bd\:m75b {margin-bottom: 75px}
	.bd\:m100b {margin-bottom: 100px}
	.bd\:m80b {margin-bottom: 80px}
	.bd\:m115b {margin-bottom: 115px}
	.bd\:m130b {margin-bottom: 130px}
	// big - left
	// big - vertical
	// big - horizontal
}

// small
@media (min-width: $screen-small) and (max-width: $screen-small-max) {
	// small - all
	// small - top
	.sm\:m30t {margin-top: 30px}
	.sm\:m40t {margin-top: 30px}
	.sm\:m45t {margin-top: 45px}
	// small - right
	// small - bottom
	.sm\:m20b- {margin-bottom: -20px}
	.sm\:m10b- {margin-bottom: -10px}
	.sm\:m10b {margin-bottom: 10px}
	.sm\:m20b {margin-bottom: 20px}
	.sm\:m25b {margin-bottom: 25px}
	.sm\:m30b {margin-bottom: 30px}
	.sm\:m35b {margin-bottom: 35px}
	.sm\:m40b {margin-bottom: 40px}
	.sm\:m40b {margin-bottom: 40px}
	.sm\:m60b {margin-bottom: 60px}
	.sm\:m50b {margin-bottom: 50px}
	.sm\:m60b {margin-bottom: 60px}
	.sm\:m70b {margin-bottom: 70px}
	// small - left
	// small - vertical
	// small - horizontal
}

// medium
@media (min-width: $screen-medium) and (max-width: $screen-medium-max) {
	// medium - all
	// medium - top
	.md\:m30t {margin-top:30px}
	.md\:m40t {margin-top:40px}
	// medium - right
	// medium - bottom
	.md\:m50b {margin-bottom:50px}
	// medium - left
	// medium - vertical
	// medium - horizontal
}

// large
@media (min-width: $screen-large) {
	// large - all
	// large - top
	.lg\:m60t {margin-top:60px}
	// large - right
	// large - bottom
	.lg\:m35b {margin-bottom:35px}
	.lg\:m60b {margin-bottom:60px}
	// large - left
	// large - vertical
	// large - horizontal
}