.form-interested-in {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(12, 1fr);

  fill: #fff;
  background-color: $primary1;
  color: #fff;

  border-radius: 1.25rem;
  padding: 5rem 0rem;

  .form-interested-in__content {
    grid-column: 3 / span 8;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    .title {
      font-size: 2.75rem;
      font-weight: 700;
      color: white;
    }

    .heading-text {
      font-size: 1.75rem;
      font-weight: 500;
      color: white;
    }

    .form-field-group {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        // align-items: center;
    }

    .form-field {
      display: flex;
      align-items: center;

      a {
        width: 100%;
        border-radius: 1.25rem;
        border-width: 2px;

        background-color: $primary5;
        color: white;
        fill: white;

        padding: 1rem 1.5rem;
      }

      .form-field__textarea {
        padding-bottom: 5rem;
      }
    }

    .action {
      display: flex;
      align-items: center;

      a {
        border-radius: 1.25rem;
        border-width: 2px;

        background-color: $secondary1;
        color: white;
        fill: white;

        padding: 1rem 1.5rem;
      }

      .action-text {
        text-align: center;
        font-weight: 600;
      }
    }
  }
}
