// - padding classes
// - sizes: global | tiny | small | medium | large
// - examples:
// - all 		| .p10  | .xs-p10  | .sm-p10  | .md-p10  | .lg-p10  | .hg-p10
// - top 		| .p10t | .xs-p10t | .sm-p10t | .md-p10t | .lg-p10t | .hg-p10t
// - right 		| .p10r | .xs-p10r | .sm-p10r | .md-p10r | .lg-p10r | .hg-p10r
// - bottom 	| .p10b | .xs-p10b | .sm-p10b | .md-p10b | .lg-p10b | .hg-p10b
// - left 		| .p10l | .xs-p10l | .sm-p10l | .md-p10l | .lg-p10l | .hg-p10l
// - vertical 	| .p10x | .xs-p10x | .sm-p10x | .md-p10x | .lg-p10x | .hg-p10x
// - horizontal | .p10y | .xs-p10y | .sm-p10y | .md-p10y | .lg-p10y | .hg-p10y
// ----------------------------------------------------------------

// global - all
.p20 {
	padding:20px;
}
.p25 {
	padding:25px;
}

// global - top
// global - right
// global - bottom
// global - left
// global - vertical
.p7y {
	padding-top: 7px;
	padding-bottom: 7px;
}
// global - horizontal
.p10x {
	padding-left:10px;
	padding-right:10px;
}

// - small device
// ---------------------------------------------------------------------------

// tiny | small
@media (max-width: $screen-tiny-max) {
	// small - all
	.sd\:p10 {padding:10px}
	.sd\:p35 {padding:35px}
	.sd\:p45 {padding:45px}
	// small - top
	.sd\:p30t {padding-top:30px}
	// small - right
	// small - bottom
	.sd\:p40b {padding-bottom:40px}
	// small - left
	// small - vertical
	.sd\:p30y {padding-top:30px; padding-bottom:30px}
	.sd\:p45y {padding-top:45px; padding-bottom:45px}
	// small - horizontal
	.sd\:p10x {padding-left:10px; padding-right:10px}
	.sd\:p20x {padding-left:20px; padding-right:20px}
	.sd\:p25x {padding-left:25px; padding-right:25px}
}

@media (max-width: $screen-mini-max) {
	// tiny - all
	// tiny - top
	// tiny - right
	// tiny - bottom
	// tiny - left
	// tiny - vertical
	// tiny - horizontal
}

@media (min-width: $screen-tiny) and (max-width: $screen-tiny-max) {
	// mini - all
	// small - top
	// small - right
	// small - bottom
	// small - left
	// small - vertical
	// small - horizontal
}

// - big device
// ---------------------------------------------------------------------------

// small | medium | large | huge
@media (min-width: $screen-small) {
	// big - all
	.bd\:p15 {padding:15px}
	.bd\:p50 {padding:50px}
	.bd\:p55 {padding:55px}
	// big - top
	// big - right
	// big - bottom
	.bd\:p100b {padding:100px}
	// big - left
	// big - vertical
	// big - horizontal
}

// small
@media (min-width: $screen-small) and (max-width: $screen-small-max) {
	// small - all
	.sm\:p35 {padding:35px}
	.sm\:p45 {padding:45px}
	.sm\:p60 {padding:60px}
	// small - top
	// small - right
	// small - bottom
	// small - left
	// small - vertical
	.sm\:p20x {padding-left:20px; padding-right:20px}
	// small - horizontal
}

// medium
@media (min-width: $screen-medium) and (max-width: $screen-medium-max) {
	// medium - all
	.md\:p60 {padding:60px}
	// medium - top
	// medium - right
	// medium - bottom
	// medium - left
	// medium - vertical
	// medium - horizontal
}

// large
@media (min-width: $screen-large) {
	// large - all
	.lg\:p60 {
		padding:60px
	}
	.lg\:p95 {
		padding:95px
	}
	// large - top
	// large - right
	// large - bottom
	// large - left
	// large - vertical
	// large - horizontal
}